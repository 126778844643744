import React, { useEffect, useRef } from "react";
import Quill from "quill";
import "quill-mention";

export default function Editor(props: {
    id: string;
    suggestions: any[];
    hideToolbar?: boolean;
    initValue: string;
    quilVar: CallableFunction;
    onPressEnter?: CallableFunction;
}) {
    const hashValues = props.suggestions;

    useEffect(() => {
        var FontStyle = Quill.import("attributors/style/font");

        FontStyle.whitelist = [
            "sans-serif",
            "monospace",
            "serif",
            "Georgia",
            "palatino-linotype",
            "times-new-roman",
            "Arial",
            "Helvetica",
            "arial-black",
            "comic-sans-ms",
            "Charcoal",
            "Impact",
            "lucida-sans-unicode",
            "lucida-grande",
            "Tahoma",
            "Geneva",
            "trebuchet-ms",
            "Verdana",
            "courier-new",
            "lucida-console",
            "Mirza",
            "Roboto",
        ];

        Quill.register(FontStyle, true);

        // let toolbarOptions = [
        //     [
        //         { font: ["", "times-new-roman", "arial"] },
        //         { size: ["14px", "16px", "18px"] },
        //         "bold",
        //         "italic",
        //         "underline",
        //         "sketch",
        //     ],
        //     ["clean"], // remove formatting button
        // ];

        var toolbarOptions = [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote"],

            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ["clean"], // remove formatting button
        ] as any;

        if (props.hideToolbar) {
            toolbarOptions = false;
        }

        let bindings = {
            enter: {
                key: 13,
                handler: function () {
                    (window as any)?.jQuery(".btn.btn-primary.sent").focus();
                    (window as any)
                        ?.jQuery(".btn.btn-primary.sent")
                        .trigger("click");

                    // props.onPressEnter?.();
                    return false;
                },
            },
        };

        let quil = new Quill("#" + props.id, {
            theme: "snow",

            modules: {
                keyboard: {
                    bindings: bindings,
                },
                toolbar: toolbarOptions,
                mention: {
                    mentionDenotationChars: ["<"],
                    source: function (searchTerm, renderList, mentionChar) {
                        let values = hashValues;
                        if (searchTerm.length === 0) {
                            renderList(values, searchTerm);
                        } else {
                            const matches = [] as any[];
                            for (let i = 0; i < values.length; i++)
                                if (
                                    ~values[i].value
                                        .toLowerCase()
                                        .indexOf(searchTerm.toLowerCase())
                                )
                                    matches.push(values[i]);
                            renderList(matches, searchTerm);
                        }
                    },
                },
            },
        });
        props.quilVar(quil);
        // alert(Keyboard.keys.ENTER);
        // quil.keyboard.addBinding(
        //     { key: Keyboard.keys.ENTER },
        //     (range, context) => {
        //         alert("press enter");
        //         return true;
        //     }
        // );
    }, []);

    return (
        <div className={props.hideToolbar ? "hide-toolbar" : ""}>
            <div
                id={props.id}
                onChange={(e) => {
                    console.log(e);
                }}
                dangerouslySetInnerHTML={{ __html: props.initValue }}
            ></div>
        </div>
    );
}
