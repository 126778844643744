import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import DataTable from "../components/DataTable";
import AppLoading from "../components/AppLoading";
import ApiService from "../services/ApiService";
import { getToken, handleErrors } from "../services/HelperMethods";
import { Fragment } from "react";
import moment from "moment";

export default function ({ history }) {
    const apiService = new ApiService();
    const [isLoading, setIsLoading] = useState(false);
    const [isWorking, setIsWorking] = useState(false);
    const [serviceMessages, setServiceMessages] = useState([] as any[]);
    const [workOrders, setWorkOrders] = useState([] as any[]);

    useEffect(() => {
        let jwt = getToken();
        if (!jwt) {
            history.push("/start/login");
        } else {
            getStatusList();
        }
    }, []);

    async function getStatusList() {
        setIsLoading(true);
        try {
            let result = (await apiService.getStatus()) as any;
            result = result.data;
            console.log("msg", result.serviceMessages);
            setServiceMessages(result.serviceMessages);

            let wo = (await apiService.getWorkOrder()) as any;
            wo = wo.data;
            addAdditionalData(wo, result.serviceMessages);
            console.log("wo", wo);
            setWorkOrders(wo);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    function addAdditionalData(wo, serviceMessages) {
        wo.forEach((item) => {
            item.firstStatus = item.list[0];
            item.lastStatus = item.list[item.list.length - 1];

            item.lastStatus.serviceMessage = serviceMessages.find((msg) => {
                return msg.id == item.lastStatus.serviceMessageId;
            });
        });
    }

    async function handleStatusChange(statusId, order, isForced = false) {
        setIsWorking(true);
        try {
            let result = (await apiService.changeWorkOrder({
                ServiceMessageId: statusId,
                Kworkorder: order.orderNumber,
                isForce: isForced,
            })) as any;
            result = result.data;

            let changedWorkOrder = workOrders.find((wo) => {
                return (
                    wo.orderNumber ==
                    result.ebsTblServiceMessagesHeader.kworkorder
                );
            });

            changedWorkOrder.list.push({
                endDate: moment(),
                id: result.ebsTblServiceMessagesHeader.id,
                // isNotificationSent:
                //     result.ebsTblServiceMessagesHeader
                //         .notificationTriggeredSuccess,
                orderNumber: result.ebsTblServiceMessagesHeader.kworkorder,
                serviceMessageId:
                    result.ebsTblServiceMessagesHeader.serviceMessageId,
                startDate: result.ebsTblServiceMessagesHeader.startDate,
            });
            addAdditionalData(workOrders, serviceMessages);
            changedWorkOrder.lastStatus.notificationTriggerTime =
                result.ebsTblServiceMessagesHeader.notificationTriggerTime;

            changedWorkOrder.lastStatus.notificationTriggeredSuccess =
                result.ebsTblServiceMessagesHeader.notificationTriggeredSuccess;

            changedWorkOrder.lastStatus.isNotificationSent =
                result.ebsTblServiceMessagesHeader.notificationTriggeredSuccess;

            changedWorkOrder.lastStatus.notificationTriggered =
                result.ebsTblServiceMessagesHeader.notificationTriggered;

            changedWorkOrder.notificationTriggerId =
                result.ebsTblServiceMessagesHeader.id;
            setWorkOrders([...workOrders]);

            console.log(result);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    async function handleNotificationCancel(item: any) {
        setIsWorking(true);
        try {
            let result = await apiService.cancelNotification(
                item.lastStatus.id
            );
            console.log("cancel result", result);
            item.lastStatus.notificationCanceled =
                result.data.notificationCanceled;

            item.lastStatus.notificationTriggeredSuccess =
                result.data.notificationTriggeredSuccess;

            item.lastStatus.isNotificationSent =
                result.data.notificationTriggeredSuccess;

            item.lastStatus.notificationTriggered =
                result.data.notificationTriggered;

            setWorkOrders([...workOrders]);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    return (
        <div className="content dashboard-wrap">
            <AppLoading isLoading={isLoading || isWorking} />
            {/* <Breadcrumb path="Dashboard" /> */}
            <div>
                {!isLoading ? (
                    <DataTable
                        notificationCancel={handleNotificationCancel}
                        statusChange={handleStatusChange}
                        serviceMessages={serviceMessages}
                        workOrders={workOrders}
                    />
                ) : (
                    <Fragment />
                )}
            </div>
        </div>
    );
}
