import React from "react";

export default function (props: { path: string }) {
    return (
        <div className="breadcrumb">
            {/* <i className="fas fa-angle-right" />  */}
            <span>{props.path}</span>
        </div>
    );
}
