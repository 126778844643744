import React, { createContext, useState } from "react";

export const NotificationContext = createContext([] as any[]);
export const ChatContext = createContext([] as any[]);
// export const RoleContext = createContext([] as any[]);
export const SidebarStatusContext = createContext([] as any[]);

export default function AppStorage({ children }) {
    const [notifications, setNotifications] = useState([]);
    const [contactItems, setContactItems] = useState([]);
    // const [chatType, setChatType] = useState([]);
    // const [role, setRole] = useState("");
    const [sideBarStatus, setSideBarStatus] = useState("small");

    return (
        <NotificationContext.Provider value={[notifications, setNotifications]}>
            <ChatContext.Provider value={[contactItems, setContactItems]}>
                {/* <RoleContext.Provider value={[role, setRole]}> */}
                <SidebarStatusContext.Provider
                    value={[sideBarStatus, setSideBarStatus]}
                >
                    {children}
                </SidebarStatusContext.Provider>
                {/* </RoleContext.Provider> */}
            </ChatContext.Provider>
        </NotificationContext.Provider>
    );
}
