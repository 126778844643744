import { Checkbox } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiService from "../services/ApiService";
import AppButton from "../components/AppButton";
import { handleErrors, saveToken } from "../services/HelperMethods";
// import { RoleContext } from "../storage/AppStorage";

export default function () {
    const history = useHistory();
    const apiService = new ApiService();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    // const [role, setRole] = useContext(RoleContext);

    return (
        <div className="login-wrapper">
            <div className="login-logo">
                <img src="/logo.png" />
            </div>
            <form>
                <div className="login-box">
                    <div className="login-input-wrap">
                        <label>Username</label>
                        <input
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                            placeholder="Type your username"
                            type="text"
                            className="form-controller"
                        />
                    </div>
                    <div className="login-input-wrap">
                        <label>Password</label>
                        <input
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            placeholder="Type your password"
                            type={showPassword ? "text" : "password"}
                            className="form-controller"
                        />
                        <div
                            className="eye"
                            onClick={() => {
                                setShowPassword(!showPassword);
                            }}
                        >
                            {showPassword ? (
                                <i className="fas fa-eye-slash"></i>
                            ) : (
                                <i className="fas fa-eye"></i>
                            )}
                        </div>
                    </div>
                    <div className="text-left">
                        <div>
                            <Checkbox
                                checked={isChecked}
                                onChange={(e) => {
                                    setIsChecked(e.target.checked);
                                }}
                                color="primary"
                                inputProps={{
                                    "aria-label": "secondary checkbox",
                                }}
                            />
                            <label>
                                <span>Remember me</span>
                            </label>
                        </div>
                    </div>
                    <AppButton
                        isLoading={isLoading}
                        onClick={() => {
                            handleLogin();
                        }}
                        style={{ marginTop: 10 }}
                        className="btn btn-primary"
                    >
                        Login
                    </AppButton>
                </div>
            </form>
        </div>
    );

    async function handleLogin() {
        setIsLoading(true);
        localStorage.setItem("isRememberPassword", isChecked.toString());

        try {
            let result = (await apiService.auth({
                username,
                password,
                remember: isChecked,
            })) as any;
            result = result.data;
            saveToken(result.token);
            localStorage.setItem("canEdit", result.canEdit);
            localStorage.setItem("lastLoginTime", new Date().toString());
            if (!result.branch) {
                result.branch = "All";
            }
            localStorage.setItem("branch", result.branch);
            history.push("/");
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }
}
