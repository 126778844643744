import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function CountDown(props: {
    time;
    afterCountdownEnd: CallableFunction;
}) {
    const [time, setTime] = useState("0");
    const [isWorking, setIsWorking] = useState(false);

    useEffect(() => {
        let timer = setInterval(() => {
            let diffInSeconds = moment
                .utc(props.time)
                .local()
                .diff(moment(), "second");
            setTime((diffInSeconds / 60).toFixed(2));
            // console.log(diffInSeconds);
            if (diffInSeconds < 0) {
                clearInterval(timer);
                setIsWorking(true);
                setTimeout(() => {
                    props.afterCountdownEnd()?.then?.(() => {
                        setIsWorking(false);
                    });
                }, 60000);
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [props.time]);

    return (
        <div className={isWorking ? "working timer" : "timer"}>
            {isWorking ? (
                <span>
                    <ClipLoader size={15} color={"white"} />
                    {/* <span>do not cancel now...</span> */}
                </span>
            ) : (
                <span>{time}</span>
            )}
        </div>
    );
}
