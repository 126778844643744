import _ from "lodash";
import moment from "moment";
import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import ApiService from "../services/ApiService";
import {
    getSuggestions,
    handleErrors,
    showNotification,
} from "../services/HelperMethods";
import { ChatContext, NotificationContext } from "../storage/AppStorage";
import AppButton from "./AppButton";
import AppLoading from "./AppLoading";
import Editor from "./Editor";

export default function ChatComponentSmall(props: {
    contactItems;
    onClose: CallableFunction;
}) {
    const [suggestions, setSuggestions] = useState([] as any[]);
    const apiService = new ApiService();
    const [textQuill, setTextQuill] = useState(null as any);
    const [isLoading, setIsLoading] = useState(false);
    const [emailSubject, setEmailSubject] = useState("Custom Message from EBS");
    const refMsg = useRef() as any;

    let timer = null as any;
    useEffect(() => {
        suggestionFn();
    }, [props.contactItems]);

    useEffect(() => {}, []);

    async function suggestionFn() {
        let suggestions = await getSuggestions();
        setSuggestions(suggestions);
    }

    return props.contactItems?.length ? (
        <div className="chat-component small">
            {/* <AppLoading isLoading={isLoading} /> */}
            <div className="row no-gutters" style={{ height: "100%" }}>
                <div className="col msg-right">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "stretch",
                            marginTop: 0,
                        }}
                    >
                        <div className="row no-gutters align-items-center justify-content-end">
                            <div className="col">
                                <div
                                    style={{
                                        color: "red",
                                        fontSize: 15,
                                        textTransform: "uppercase",
                                        textAlign: "left",
                                        padding: "0 15px",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Send to all Work orders
                                </div>
                            </div>
                            {/* <div className="col-auto order-sm-2">
                                <button
                                    onClick={() => {
                                        props.onClose();
                                    }}
                                    className="btn btn-close "
                                >
                                    <i className="fas fa-share mr-1"></i>
                                    Trigger current notification
                                </button>
                            </div> */}
                            <div className="col-auto order-sm-2">
                                <button
                                    onClick={() => {
                                        props.onClose();
                                    }}
                                    className="btn btn-close "
                                >
                                    <i className="fas fa-times mr-1"></i>
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ padding: 10 }}>
                            <label>Email Subject</label>
                            <input
                                value={emailSubject}
                                onChange={(e) => {
                                    setEmailSubject(e.target.value);
                                }}
                                style={{
                                    backgroundColor: "#eaeaea",
                                    color: "black",
                                    // fontWeight:'bold'
                                    border: "1px solid lightgray",
                                }}
                                className="form-control"
                            />
                        </div>
                        <div className="msg-send-wrap">
                            <div className="editor-wrap">
                                <Editor
                                    initValue={""}
                                    suggestions={suggestions}
                                    id={"chatbox"}
                                    hideToolbar={true}
                                    quilVar={(quill) => {
                                        setTextQuill(quill);
                                    }}
                                />
                            </div>
                            <AppButton
                                isLoading={isLoading}
                                onClick={() => {
                                    handleSend();
                                }}
                                className="btn btn-primary"
                            >
                                <i className="fas fa-paper-plane mr-1"></i>
                                Send
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Fragment />
    );

    async function handleSend() {
        setIsLoading(true);
        let orderNumbers = props.contactItems.map((item) => {
            return { workorder: item.orderNumber };
        });
        try {
            let data = {
                TemplateEmailHeader: emailSubject,
                TemplateEmailBody: textQuill?.root?.innerHTML,
                TemplateText: textQuill?.root?.innerHTML,
                WorkOrders: orderNumbers,
            };
            let result = await apiService.sendMessage(data);
            textQuill.setContents("");
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }
}
