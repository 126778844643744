import {
    Paper,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    TableBody,
    Tooltip,
    Select,
    MenuItem,
    TablePagination,
    TextField,
} from "@material-ui/core";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import AppLoading from "../components/AppLoading";
import ChatHistory from "../components/ChatHistory";
import ApiService from "../services/ApiService";
import { handleErrors } from "../services/HelperMethods";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import AppPagination from "../components/AppPagination";
import _, { result } from "lodash";

export default function () {
    const apiService = new ApiService();
    const [isLoading, setIsLoading] = useState(false);
    const [originalWoHistory, setOriginalWoHistory] = useState([] as any[]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [woLength, setWoLength] = useState(0);
    const [isShowFilter, setIsShowFilter] = useState(true);

    const [filterDate, setFilterDate] = useState(null as any);
    const [filterDateColumn, setFilterDateColumn] = useState("");
    const [filterWO, setFilterWO] = useState("");
    const [filterCusName, setFilterCusName] = useState("");
    const [filterCusNumber, setFilterCusNumber] = useState("");
    const [filterMFR, setFilterMFR] = useState("");
    const [filterModel, setFilterModel] = useState("");
    const [filterEqId, setFilterEqId] = useState("");
    const [filterSerial, setFilterSerial] = useState("");
    const [filterTechName, setFilterTechName] = useState("");
    const [selectedWO, setSelectedWO] = useState(null as any);
    const [selectedSortedInfo, setSelectedSortedInfo] = useState({
        column: "",
        order: false,
    });

    const [woHistory, setWoHistory] = useState([] as any[]);
    const [pagedWoHistory, setPagedWoHistory] = useState([] as any[]);

    //init
    useEffect(() => {
        getHistory();
    }, []);

    //change page
    useEffect(() => {
        setPageFunc(page, woHistory);
    }, [page, rowsPerPage, originalWoHistory]);

    //filter
    useEffect(() => {
        setPage(0);
        let result = getAllFilterResult();
        setWoHistory(result);
        setPageFunc(0, result);
        setSelectedSortedInfo({
            column: "",
            order: false,
        });
    }, [
        filterDate,
        filterDateColumn,
        filterWO,
        filterCusName,
        filterCusNumber,
        filterMFR,
        filterModel,
        filterEqId,
        filterSerial,
        filterTechName,
    ]);

    //sort
    useEffect(() => {
        let result = getAllFilterResult();
        // let sortData = sortFunc(
        //     selectedSortedInfo.column,
        //     selectedSortedInfo.order,
        //     result
        // );
        let sortData = getSortDataFunc();
        setWoHistory(sortData);
        setPageFunc(0, sortData);
    }, [selectedSortedInfo]);

    function getAllFilterResult() {
        let result = originalWoHistory;
        result = search(filterWO, "workOrder", result);
        result = search(filterCusName, "customerName", result);
        result = search(filterCusNumber, "customerNumber", result);
        result = search(filterMFR, "mfg", result);
        result = search(filterModel, "model", result);
        result = search(filterEqId, "equipmentID", result);
        result = search(filterSerial, "serialNumber", result);
        result = search(filterTechName, "techName", result);
        //filter by date
        result = searchDate(
            moment(filterDate?.[0]),
            moment(filterDate?.[1]),
            filterDateColumn,
            result
        );

        return result;
    }

    async function getHistory() {
        setIsLoading(true);
        try {
            let result = await apiService.getWorkOrderHistory();
            setOriginalWoHistory(result.data.orderHistories);
            setWoHistory(result.data.orderHistories);
            setPageFunc(0, result.data.orderHistories);
            console.log("history", result.data);
            setWoLength(result.data.orderHistories.length);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    function setPageFunc(page, data = originalWoHistory) {
        setPage(page);
        // let sortData = getSortDataFunc();
        let sortData = data;
        let pagedData = sortData.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
        );
        setPagedWoHistory(pagedData);

        // sort data
        if (selectedSortedInfo) {
            // sortDataFunc(selectedSortedInfo.column, selectedSortedInfo.order);
        }
    }

    function getSortDataFunc() {
        if (!selectedSortedInfo.column) {
            return woHistory;
        }
        let sortData = _.orderBy(
            woHistory,
            selectedSortedInfo.column,
            selectedSortedInfo.order ? "asc" : "desc"
        );
        return sortData;
    }

    function getTableHeader(selector, text) {
        return (
            <TableSortLabel
                onClick={() => {
                    setSelectedSortedInfo({
                        order:
                            selectedSortedInfo.column == selector
                                ? !selectedSortedInfo.order
                                : true,
                        column: selector,
                    });
                }}
                direction={
                    selectedSortedInfo.column == selector &&
                    selectedSortedInfo.order
                        ? "asc"
                        : "desc"
                }
                active={selectedSortedInfo.column == selector ? true : false}
            >
                {text}
            </TableSortLabel>
        );
    }

    if (isLoading) {
        return (
            <div className="content history">
                <AppLoading isLoading={true} />
            </div>
        );
    }

    return (
        <div className="content history">
            <Paper className="pt-2">
                <div className="data-grid-header">
                    <h1 className="data-table-title">History</h1>
                    <div className="show-filter-button">
                        <div style={{ margin: 0 }}>
                            <button
                                onClick={() => {
                                    setIsShowFilter(!isShowFilter);
                                }}
                                className="btn btn-sm"
                                style={{ marginRight: 10 }}
                            >
                                {isShowFilter ? "Hide Filter" : "Show Filter"}
                            </button>
                        </div>
                    </div>
                </div>
                {filterUI()}
                <ChatHistory
                    onClose={() => {
                        setSelectedWO(null);
                    }}
                    contactItems={selectedWO ? [selectedWO] : []}
                />

                <TableContainer className="data-table-history data-table-wrap">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <div>
                                        {getTableHeader("workOrder", "WO #")}
                                    </div>
                                </TableCell>
                                <TableCell>
                                    {getTableHeader(
                                        "customerName",
                                        "Customer Name"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader(
                                        "customerNumber",
                                        " Customer #"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader("mfg", "MFG")}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader("model", "Model")}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader(
                                        "equipmentID",
                                        "Equipment ID"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader(
                                        "serialNumber",
                                        "Serial Number"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader(
                                        "dateOpened",
                                        "Date Opened"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader(
                                        "dateClosed",
                                        "Date Closed"
                                    )}
                                </TableCell>
                                <TableCell>
                                    {getTableHeader("techName", "Tech Name")}
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {pagedWoHistory.map((item, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        style={
                                            index % 2
                                                ? { background: "#f3f3f3" }
                                                : { background: "white" }
                                        }
                                    >
                                        <TableCell
                                            onClick={() => {
                                                setSelectedWO(item);
                                            }}
                                        >
                                            <span
                                                style={{
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {item.workOrder}
                                            </span>
                                        </TableCell>
                                        <TableCell>
                                            {item.customerName}
                                        </TableCell>
                                        <TableCell>
                                            {item.customerNumber}
                                        </TableCell>
                                        <TableCell>{item.mfg}</TableCell>
                                        <TableCell>{item.model}</TableCell>
                                        <TableCell>
                                            {item.equipmentID}
                                        </TableCell>
                                        <TableCell>
                                            {item.serialNumber}
                                        </TableCell>
                                        <TableCell>
                                            {moment
                                                .utc(item.dateOpened)
                                                .local()
                                                .format("MM/DD/YYYY hh:mm A")}
                                        </TableCell>
                                        <TableCell>
                                            {moment
                                                .utc(item.dateClosed)
                                                .local()
                                                .format("MM/DD/YYYY hh:mm A")}
                                        </TableCell>
                                        <TableCell>{item.techName}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                    }}
                >
                    <TablePagination
                        rowsPerPageOptions={[20, 50, 100, 200, 500, 1000]}
                        component="div"
                        count={Math.ceil(woHistory.length / rowsPerPage)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={(event, page) => {
                            // setPage(page);
                            // setPageSet(5 * page);
                        }}
                        onChangeRowsPerPage={(event) => {
                            setRowsPerPage(parseInt(event.target.value, 10));
                            setPageFunc(0);
                        }}
                    />
                    <div className="pagination-wrap">
                        <AppPagination
                            items={woHistory}
                            onChangePage={(pageNumber) => {
                                setPageFunc(pageNumber);
                            }}
                            rowsPerPage={rowsPerPage}
                            currentPage={page}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    );

    function search(searchVal, column, data) {
        searchVal = searchVal.toLowerCase();

        if (!searchVal) {
            return data;
        }

        var result = _.filter(data, (i) => {
            return i?.[column]?.toLowerCase().includes(searchVal);
        });

        return result;
    }

    function sortFunc(name, toggleOrder = true, data) {
        let sortData = [] as any[];
        if (toggleOrder) {
            sortData = _.orderBy(data, name, "asc");
        } else {
            sortData = _.orderBy(data, name, "desc");
        }
        return sortData;
    }

    function searchDate(
        fromDate: moment.Moment,
        toDate: moment.Moment,
        column,
        data
    ) {
        if (!toDate || !fromDate || !column || fromDate.isSame(toDate)) {
            return data;
        }

        var result = _.filter(data, (i) => {
            return moment(i?.[column])
                .utc()
                .local()
                .isBetween(fromDate, toDate);
        });

        return result;
    }

    function filterUI() {
        if (!isShowFilter) {
            return <Fragment />;
        }
        return (
            <div className="filter-wrap">
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterWO(e.target.value);
                        }}
                        label="WO #"
                        className="filter-input"
                    />
                </div>
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterCusName(e.target.value);
                        }}
                        label="Cus. Name"
                        className="filter-input"
                    />
                </div>
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterCusNumber(e.target.value);
                        }}
                        label="Cus. #"
                        className="filter-input"
                    />
                </div>
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterMFR(e.target.value);
                        }}
                        label="MFG"
                        className="filter-input"
                    />
                </div>
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterModel(e.target.value);
                        }}
                        label="Model"
                        className="filter-input"
                    />
                </div>
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterEqId(e.target.value);
                        }}
                        label="Equipment ID"
                        className="filter-input"
                    />
                </div>
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterSerial(e.target.value);
                        }}
                        label="Serial"
                        className="filter-input"
                    />
                </div>
                <Select
                    value={filterDateColumn}
                    onChange={(e) => {
                        setFilterDateColumn(e.target.value as string);
                    }}
                >
                    <MenuItem value="">
                        <span className="status-item"></span>
                    </MenuItem>
                    <MenuItem value="dateOpened">
                        <span className="status-item">Opened</span>
                    </MenuItem>

                    <MenuItem value="dateClosed">
                        <span className="status-item">Closed</span>
                    </MenuItem>
                </Select>
                <DateRangePicker
                    value={filterDate}
                    onChange={(e) => {
                        setFilterDate(e);
                    }}
                />
                <div>
                    <TextField
                        onChange={(e) => {
                            setFilterTechName(e.target.value);
                        }}
                        label="Tech"
                        className="filter-input"
                    />
                </div>
            </div>
        );
    }
}
