import "./App.scss";
import React from "react";
import Sidebar from "./components/Sidebar";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import HistoryPage from "./pages/History";
import Messages from "./pages/Messages";
import UserProfile from "./pages/UserProfile";
import MergeFields from "./pages/MergeFields";
import Toolbar from "./components/Toolbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-quill/dist/quill.snow.css";
import "@simonwep/pickr/dist/themes/classic.min.css";

import AppStorage from "./storage/AppStorage";
import ChatComponent from "./components/ChatComponent";

function App() {
    return (
        <AppStorage>
            <div className="App">
                <ToastContainer />
                <Router>
                    <Switch>
                        <Route path="/start">
                            <Switch>
                                <Route
                                    path="/start/login"
                                    exact
                                    component={Login}
                                ></Route>
                            </Switch>
                        </Route>

                        <Route path="/">
                            <div className="wrapper">
                                <div className="main-panel small">
                                    <Sidebar />
                                    <Toolbar />
                                    <Switch>
                                        <Route
                                            path="/"
                                            exact
                                            component={Dashboard}
                                        />
                                        <Route
                                            path="/history"
                                            exact
                                            component={HistoryPage}
                                        />
                                        <Route
                                            path="/messages"
                                            exact
                                            component={Messages}
                                        />
                                        <Route
                                            path="/merge-fields"
                                            exact
                                            component={MergeFields}
                                        />
                                        <Route
                                            path="/user-profile"
                                            exact
                                            component={UserProfile}
                                        />
                                    </Switch>
                                </div>
                                <ChatComponent />
                            </div>
                        </Route>
                    </Switch>
                </Router>
            </div>
        </AppStorage>
    );
}

export default App;
