import React, { useContext, useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import CountDown from "../components/CountDown";
import AppPagination from "../components/AppPagination";
import ChatComponentSmall from "../components/ChatComponentSmall";

import {
    Checkbox,
    FormControl,
    Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import moment from "moment";
import { handleErrors, showNotification } from "../services/HelperMethods";
import { Fragment } from "react";
import _ from "lodash";
import { AddRounded, SortRounded } from "@material-ui/icons";
import ApiService from "../services/ApiService";
import { ChatContext, NotificationContext } from "../storage/AppStorage";
import Modal from "react-modal-resizable-draggable";

export default function EnhancedTable(props: {
    serviceMessages: any[];
    workOrders: any[];
    statusChange: CallableFunction;
    notificationCancel: CallableFunction;
}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [sortedInfo, setSortedInfo] = useState({ column: "", order: "" });
    const [searchedText, setSearchedText] = useState("");
    const [notificationFilter, setNotificationFilter] = useState("0" as any);
    const [filterStatus, setFilterStatus] = useState([] as any[]);
    const [contactOrders, setContactOrders] = useContext(ChatContext);
    const [contactOrdersForChatSmall, setContactOrderForChatSmall] = useState(
        [] as any
    );
    const [selectedWo, setSelectedWO] = useState([] as any);
    const [notifications, setNotifications] = useContext(NotificationContext);

    const apiService = new ApiService();

    const [data, setData] = useState(props.workOrders as any[]);
    const [pagedData, setPagedData] = useState([] as any[]);
    const today = moment();
    const $ = (window as any).jQuery;
    const [totalDivWidth, setTotalDivWidth] = useState(500);

    useEffect(() => {
        let width = $(".content.dashboard-wrap").width() - 650;
        setTotalDivWidth(width);
    }, []);

    useEffect(() => {
        // let initData = !data?.length ? props.workOrders : data;
        // debugger;
        let d = getPagedData(data);
        console.log("paged data", d);
        setPagedData(d);
        // d = sortData(sortedInfo.column);
        var allIds = props.serviceMessages.map((i) => {
            return i.id;
        });
        setFilterStatus(allIds);
    }, [rowsPerPage, page]);

    function getPagedData(data) {
        if (data) {
            // let fixedDate = [] as any;
            // data.forEach((item) => {
            //     item.list.forEach((listItem) => {
            //         if (!listItem.endDate) {
            //             listItem.endDate = moment();
            //         }
            //         if (!listItem.startDate) {
            //             listItem.startDate = moment();
            //         }
            //     });
            //     fixedDate.push(item);
            // });

            let d = data.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
            );

            return d;
        }
        return null;
    }

    const handleChangePage = (selectedPageNumber) => {
        setPage(selectedPageNumber);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function sortData(name) {
        let initData = data;

        let sortDirection = "asc" as any;
        if (sortedInfo.column == name && sortedInfo.order == "asc") {
            sortDirection = "desc";
        }

        let sortData = [] as any[];

        if (name == "wo") {
            sortData = _.orderBy(initData, "orderNumber", sortDirection);
        }
        if (name == "timeline") {
            sortData = _.orderBy(
                initData,
                "lastStatus.startDate",
                sortDirection
            );
        }
        if (name == "status") {
            sortData = _.orderBy(
                initData,
                "lastStatus.serviceMessage.statusMessage",
                sortDirection
            );
        }
        if (name == "notification") {
            sortData = _.orderBy(
                initData,
                "lastStatus.isNotificationSent",
                sortDirection
            );
        }

        if (sortData.length) {
            setSortedInfo({
                column: name,
                order: sortDirection,
            });
            return sortData;
        } else {
            return [];
        }
    }

    // function search(search, data = props.workOrders) {
    //     if (search) {
    //         let filtered = data.filter((item) => {
    //             return item.orderNumber.includes(search);
    //         });
    //         setSortedInfo({ column: "", order: "asc" });
    //         return filtered;
    //     } else {
    //         return data;
    //     }
    // }

    function handleFilter(statusList, selectedNotification, searchText) {
        let allD = props.workOrders;

        if (searchText) {
            allD = allD.filter((item) => {
                return item.orderNumber.includes(searchText);
            });
        }

        // status filter
        if (statusList.includes(-2)) {
            allD = [];
        }
        // else if (statusList.includes(-1)) {
        //     // allD = allD;
        // }
        else if (
            statusList.length &&
            statusList.length != props.serviceMessages.length
        ) {
            let statusFilter = allD.filter((d) => {
                return statusList.includes(d.lastStatus.serviceMessageId);
            });
            allD = statusFilter;
        }

        if (selectedNotification == 1) {
            allD = allD.filter((d) => {
                return d.lastStatus.isNotificationSent;
            });
        }
        if (selectedNotification == 2) {
            allD = allD.filter((d) => {
                return !d.lastStatus.isNotificationSent;
            });
        }

        return allD;

        // let d = getPagedData(allD);
        // console.log("paged d", d);
        // // sortData(sortedInfo.column, false, d);
        // setData(d);
    }

    function getSelectedMessageNames(selected) {
        if (selected.length == props.serviceMessages.length) {
            return "All Status";
        }

        let names = [] as any[];
        props.serviceMessages.forEach((e) => {
            selected.forEach((select) => {
                if (select == e.id) {
                    names.push(e.statusMessage);
                }
            });
        });

        return (
            <span>
                ({names.length}):{names.join(",")}
            </span>
        );
    }

    return (
        <div>
            {/* <Modal
                isOpen={true}
                onRequestClose={() => {}}
                onFocus={() => console.log("Modal is clicked")}
                className={"my-modal-custom-class"}
                initWidth={800}
                initHeight={400}
            > */}
            <ChatComponentSmall
                onClose={() => {
                    setContactOrderForChatSmall([]);
                }}
                contactItems={contactOrdersForChatSmall}
            />
            {/* </Modal> */}

            <div className="text-right">
                <button
                    onClick={() => {
                        let corders = [] as any[];
                        if (!selectedWo?.length) {
                            props.workOrders.forEach((item) => {
                                corders.push(item);
                            });
                            setContactOrderForChatSmall(corders);
                        } else {
                            selectedWo.forEach((item) => {
                                corders.push(item);
                            });
                            corders = _.uniq(corders);
                            setContactOrders(corders);
                        }
                    }}
                    className="btn btn-sm btn-secondary btn-send-msg"
                >
                    Send message
                </button>
            </div>
            <Paper>
                <div className="data-grid-header">
                    <h1 className="data-table-title">Service Dashboard</h1>
                    <div className="filters" style={{ padding: 5 }}>
                        <div>
                            <TextField
                                onChange={(e) => {
                                    let searchedData = handleFilter(
                                        filterStatus,
                                        notificationFilter,
                                        e.target.value
                                    );
                                    setData(searchedData);
                                    let pagedData = getPagedData(searchedData);
                                    setPagedData(pagedData);
                                    setSearchedText(e.target.value);
                                    setPage(0);
                                }}
                                className="search"
                                label="WORK ORDER NUMBER"
                            />
                        </div>
                        <div>
                            <Select
                                multiple
                                style={{
                                    textTransform: "uppercase",
                                    fontSize: 12,
                                    fontWeight: "bold",
                                    width: 160,
                                    overflow: "hidden",
                                }}
                                value={filterStatus}
                                onChange={(event: any) => {
                                    let data = handleFilter(
                                        event.target.value,
                                        notificationFilter,
                                        searchedText
                                    );
                                    setData(data);
                                    setPagedData(getPagedData(data));
                                    setPage(0);

                                    if (event.target.value.includes(-2)) {
                                        return;
                                    }
                                    if (event.target.value.includes(-1)) {
                                        return;
                                    }
                                    setFilterStatus(event.target.value);
                                }}
                                renderValue={(selected: any) => {
                                    return getSelectedMessageNames(selected);
                                }}
                                input={<Input />}
                            >
                                <MenuItem
                                    onClick={() => {
                                        var allIds = props.serviceMessages.map(
                                            (i) => {
                                                return i.id;
                                            }
                                        );
                                        setFilterStatus(allIds);
                                    }}
                                    value={-1}
                                >
                                    <span className="status-item">
                                        All Status
                                    </span>
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setFilterStatus([]);
                                    }}
                                    value={-2}
                                >
                                    <span className="status-item">None</span>
                                </MenuItem>
                                {props.serviceMessages.map((s, i) => {
                                    return (
                                        <MenuItem key={i} value={s.id}>
                                            <Checkbox
                                                checked={
                                                    filterStatus.indexOf(s.id) >
                                                    -1
                                                }
                                            />
                                            <span className="status-item">
                                                {s.statusMessage}
                                            </span>
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>
                        <div>
                            <Select
                                style={{
                                    width: 160,
                                    textTransform: "uppercase",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                }}
                                onChange={(e) => {
                                    const val = e.target.value;
                                    let data = handleFilter(
                                        filterStatus,
                                        val,
                                        searchedText
                                    );
                                    setData(data);
                                    setPagedData(getPagedData(data));
                                    setPage(0);

                                    setNotificationFilter(val);
                                }}
                                value={notificationFilter}
                            >
                                <MenuItem value="0">
                                    <span className="status-item">
                                        Contacted Status
                                    </span>
                                </MenuItem>
                                <MenuItem value="2">
                                    <span className="status-item">
                                        Not Contacted
                                    </span>
                                </MenuItem>
                                <MenuItem value="1">
                                    <span className="status-item">
                                        Contacted
                                    </span>
                                </MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>

                <TableContainer className="data-table-wrap">
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        style={{ width: 20 }}
                                        direction="desc"
                                    >
                                        #
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={() => {
                                            let data = sortData("wo");
                                            setData(data);
                                            setPagedData(getPagedData(data));
                                        }}
                                        direction={
                                            sortedInfo.column == "wo"
                                                ? (sortedInfo.order as any)
                                                : "asc"
                                        }
                                        active={sortedInfo.column == "wo"}
                                    >
                                        WO
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={() => {
                                            let data = sortData("timeline");
                                            setData(data);
                                            setPagedData(getPagedData(data));
                                        }}
                                        direction={
                                            sortedInfo.column == "timeline"
                                                ? (sortedInfo.order as any)
                                                : "asc"
                                        }
                                        active={sortedInfo.column == "timeline"}
                                    >
                                        Timeline
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={() => {
                                            let data = sortData("status");
                                            setData(data);
                                            setPagedData(getPagedData(data));
                                        }}
                                        direction={
                                            sortedInfo.column == "status"
                                                ? (sortedInfo.order as any)
                                                : "asc"
                                        }
                                        active={sortedInfo.column == "status"}
                                    >
                                        Status
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <TableSortLabel
                                        onClick={() => {
                                            let data = sortData("notification");
                                            setData(data);
                                            setPagedData(getPagedData(data));
                                        }}
                                        direction={
                                            sortedInfo.column == "notification"
                                                ? (sortedInfo.order as any)
                                                : "asc"
                                        }
                                        active={
                                            sortedInfo.column == "notification"
                                        }
                                        style={{ width: 70 }}
                                    >
                                        Contact
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => {}}>
                                        <Checkbox
                                            onChange={(e, val) => {
                                                if (val) {
                                                    //select all items
                                                    let wo = [] as any[];
                                                    data.forEach((d: any) => {
                                                        wo.push(d);
                                                    });
                                                    setSelectedWO(wo);
                                                } else {
                                                    setSelectedWO([]);
                                                }
                                            }}
                                        />
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {pagedData.map((item, index) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={index}
                                        style={
                                            index % 2
                                                ? { background: "#f3f3f3" }
                                                : { background: "white" }
                                        }
                                    >
                                        <TableCell>
                                            {checkWoNotification(
                                                item.orderNumber
                                            ) ? (
                                                <div
                                                    onClick={async () => {
                                                        setContactOrders([
                                                            item,
                                                        ]);
                                                    }}
                                                    className="mail-icon"
                                                >
                                                    <i className="far fa-envelope"></i>
                                                </div>
                                            ) : (
                                                <Fragment />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <div
                                                style={{
                                                    textDecoration: "underline",
                                                }}
                                                onClick={() => {
                                                    let existItems = [
                                                        ...contactOrders,
                                                    ];
                                                    existItems.push(item);
                                                    existItems = _.uniq(
                                                        existItems
                                                    );

                                                    setContactOrders(
                                                        existItems
                                                    );
                                                }}
                                                className={"cursor-pointer"}
                                            >
                                                {item.orderNumber}
                                            </div>
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            style={{
                                                width: "100%",
                                                position: "relative",
                                            }}
                                        >
                                            <span className="badge badge-white total-days ml-2">
                                                {getTotalDays(item)}
                                            </span>
                                            <div className="progress-bar-wrap">
                                                {item.list.map((status, i) => {
                                                    if (
                                                        !status.isNotificationSent
                                                    ) {
                                                        return (
                                                            <Fragment key={i} />
                                                        );
                                                    }
                                                    return (
                                                        <div
                                                            key={i}
                                                            className="item-wrap"
                                                        >
                                                            <Tooltip
                                                                title={getTooltip(
                                                                    status
                                                                )}
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: getWidth(
                                                                            status,
                                                                            item
                                                                        ),
                                                                        minWidth: 20,
                                                                        backgroundColor: getColor(
                                                                            status
                                                                        ),
                                                                        height: 20,
                                                                        marginLeft: 1,
                                                                        border:
                                                                            "1px solid #a5a5a5",
                                                                    }}
                                                                ></div>
                                                            </Tooltip>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="last-status-description">
                                                {
                                                    item.lastStatus
                                                        .serviceMessage
                                                        ?.statusMessage
                                                }{" "}
                                                {moment
                                                    .utc(
                                                        item.lastStatus
                                                            .startDate
                                                    )
                                                    .local()
                                                    .format("MM/DD/YYYY")}{" "}
                                                (
                                                {moment().diff(
                                                    moment
                                                        .utc(
                                                            item.lastStatus
                                                                .startDate
                                                        )
                                                        .local(),
                                                    "days"
                                                )}
                                                )
                                            </div>
                                        </TableCell>
                                        <TableCell align="left">
                                            <FormControl>
                                                {!getLastStatus(item) ? (
                                                    <InputLabel
                                                        style={{ fontSize: 12 }}
                                                        id="select-status"
                                                    >
                                                        SELECT STATUS
                                                    </InputLabel>
                                                ) : (
                                                    <Fragment />
                                                )}
                                                <Select
                                                    id="select-status"
                                                    style={{
                                                        width: 150,
                                                        textTransform:
                                                            "uppercase",
                                                        fontSize: 12,
                                                    }}
                                                    placeholder="Select status"
                                                    value={getLastStatus(item)}
                                                    onChange={(e) => {
                                                        props.statusChange(
                                                            e.target.value,
                                                            item
                                                        );
                                                    }}
                                                >
                                                    {props.serviceMessages.map(
                                                        (s, i) => {
                                                            return (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={s.id}
                                                                >
                                                                    <span className="status-item">
                                                                        {
                                                                            s.statusMessage
                                                                        }
                                                                    </span>
                                                                </MenuItem>
                                                            );
                                                        }
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </TableCell>
                                        <TableCell align="left" padding="none">
                                            <div className="action-button-wrap">
                                                {checkNotificationTimer(item)}
                                            </div>
                                        </TableCell>

                                        <TableCell align="left">
                                            <div>
                                                <Checkbox
                                                    onChange={(x, y) => {
                                                        let wo = [
                                                            ...selectedWo,
                                                        ];

                                                        if (y) {
                                                            wo.push(item);
                                                            setSelectedWO(wo);
                                                        } else {
                                                            let index = checkSelectedWoContains(
                                                                item
                                                            );
                                                            wo.splice(index, 1);
                                                            setSelectedWO(wo);
                                                        }
                                                        console.log(x, y);
                                                    }}
                                                    value={
                                                        checkSelectedWoContains(
                                                            item
                                                        ) >= 0
                                                    }
                                                    checked={
                                                        checkSelectedWoContains(
                                                            item
                                                        ) >= 0
                                                    }
                                                />
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div
                    style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        backgroundColor: "white",
                    }}
                >
                    <TablePagination
                        rowsPerPageOptions={[20, 50, 100, 200, 500, 1000]}
                        component="div"
                        count={Math.ceil(data.length / rowsPerPage)}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                    <div className="pagination-wrap">
                        <AppPagination
                            items={data}
                            onChangePage={handleChangePage}
                            rowsPerPage={rowsPerPage}
                            currentPage={page}
                        />
                    </div>
                </div>
            </Paper>
        </div>
    );

    function checkWoNotification(id) {
        let isFound = null;
        notifications?.forEach((n) => {
            if (n.kworkorder == id) {
                isFound = n;
            }
        });

        return isFound;
    }

    function checkSelectedWoContains(item) {
        let isContain = selectedWo.findIndex((x) => {
            return x.orderNumber == item.orderNumber;
        });

        return isContain;
    }

    function checkNotificationTimer(item) {
        if (
            item.lastStatus.notificationTriggerTime &&
            item.lastStatus.notificationCanceled != true &&
            item.lastStatus.isNotificationSent == false &&
            item.lastStatus.notificationTriggered == true &&
            item.lastStatus.notificationTriggeredSuccess == false &&
            (item.lastStatus?.serviceMessage?.auto == true ||
                item.lastStatus?.isForced)
        ) {
            let diffInSeconds = moment
                .utc(item.lastStatus.notificationTriggerTime)
                .local()
                .add(1.5, "minute")
                .diff(moment(), "second");

            if (diffInSeconds > 0) {
                return (
                    <div
                        onClick={() => {
                            props.notificationCancel(item);
                        }}
                        className="cursor-pointer"
                    >
                        <div className="cancel-notification">
                            <i className="fas fa-times"></i>
                        </div>
                        <span>
                            <CountDown
                                afterCountdownEnd={async () => {
                                    try {
                                        let result = await apiService.checkNotificationSend(
                                            item.notificationTriggerId
                                        );
                                        console.log("result", result);
                                        item.lastStatus.isNotificationSent =
                                            result.data.isNotificationSent;
                                        setData([...data]);
                                    } catch (error) {
                                        handleErrors(error);
                                    }
                                    // item.lastStatus.notificationCanceled = true;
                                    setData([...data]);
                                    // showNotification(
                                    //     "Work Order: " +
                                    //         item.orderNumber +
                                    //         " notification trigged. refresh to see the result.",
                                    //     "info"
                                    // );
                                }}
                                time={item.lastStatus.notificationTriggerTime}
                            />
                        </span>
                        {/* <span className="badge">Sending in</span> */}
                    </div>
                );
            }
        }

        return (
            <div
                className="notification-icon-wrap"
                onClick={() => {
                    // let existItems = [...contactOrders];
                    // existItems.push(item);
                    // existItems = _.uniq(existItems);
                    // setContactOrders(existItems);
                    props.statusChange(
                        item.lastStatus.serviceMessageId,
                        item,
                        true
                    );
                    // item.lastStatus.isForced = true;
                    console.log("selected item", item);
                }}
                style={{
                    backgroundColor: isLastNotificationSent(item)
                        ? "#27ae60"
                        : "#f54542",
                }}
            >
                {isLastNotificationSent(item) ? (
                    <img
                        className="notification-icon"
                        src="/assets/images/message-sent.png"
                    />
                ) : (
                    <img
                        className="notification-icon"
                        src="/assets/images/message-not-sent.png"
                    />
                )}
            </div>
        );
    }

    function getColor(status) {
        var selectedServiceMsg = props.serviceMessages.find((item) => {
            return item.id == status.serviceMessageId;
        });

        if (selectedServiceMsg) {
            return "#" + selectedServiceMsg.displayColor;
        }
        return "white";
    }

    function getTotalDays(item) {
        let totalDuration = moment().diff(
            moment(item.firstStatus.startDate),
            "days"
        );

        // let totalWOOpenDuration = 0;
        // let woOpenStatus = null as any;
        // item.list.forEach((i) => {
        //     if (i.serviceMessageId == 6) {
        //         woOpenStatus = i;
        //     }
        // });

        // if (woOpenStatus) {
        //     totalWOOpenDuration += moment().diff(
        //         moment(woOpenStatus.endDate),
        //         "days"
        //     );
        // }

        return "Days " + totalDuration;
    }

    function isLastNotificationSent(item) {
        return item.list[item.list.length - 1].notificationTriggeredSuccess;
    }

    function getLastStatus(item) {
        if (item.list[item.list.length - 1].serviceMessageId == 0) {
            return "";
        }
        let id = item.list[item.list.length - 1].serviceMessageId;
        var isContain = props.serviceMessages.find((item) => id == item.id);
        if (!isContain) {
            return "";
        }

        return id;
    }

    function getTooltip(status) {
        let diff = moment(status.endDate).diff(
            moment(status.startDate),
            "days"
        );

        var selectedServiceMsg = props.serviceMessages.find((item) => {
            return item.id == status.serviceMessageId;
        });

        if (!selectedServiceMsg) {
            return "";
        }

        let endDate = status.endDate
            ? " - " + moment(status.endDate).format("MM/DD/YYYY")
            : "(current date)";

        return (
            <span>
                {selectedServiceMsg.statusMessage}
                <br />
                {moment(status.startDate).format("MM/DD/YYYY") +
                    +" " +
                    endDate.toString() +
                    " (" +
                    diff +
                    " days)"}
            </span>
        );
    }

    function getWidth(status, item) {
        // if (item.orderNumber == "2178") {
        //     debugger;
        // }
        if (status.endDate == null) {
            status.endDate = today;
        }
        let duration = moment(status.endDate).diff(
            moment(status.startDate),
            "hours"
        );

        if (duration < 20) {
            duration = 20;
        }

        let totalDuration = 50;
        item.list.forEach((i) => {
            if (!i.endDate) {
                i.endDate = today;
            }
            totalDuration += moment(i.endDate).diff(
                moment(i.startDate),
                "hours"
            );
        });

        let width = 1 * duration;
        if (totalDuration > totalDivWidth) {
            let oneItemPercentage = totalDivWidth / totalDuration;
            width = duration * oneItemPercentage;
        }
        if (isNaN(width)) {
            return 0;
        }
        return width;
    }
}
