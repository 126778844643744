import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import ApiService from "../services/ApiService";
import {
    getSuggestions,
    handleErrors,
    showNotification,
} from "../services/HelperMethods";
import { ChatContext, NotificationContext } from "../storage/AppStorage";
import AppButton from "./AppButton";
import AppLoading from "./AppLoading";
import Editor from "./Editor";

export default function ChatComponent(props: {
    // contactItems;
    // onClose: CallableFunction;
}) {
    const [suggestions, setSuggestions] = useState([] as any[]);
    const apiService = new ApiService();
    const [textQuill, setTextQuill] = useState(null as any);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWo, setSelectedWo] = useState(null as any);
    const [messages, setMessages] = useState([] as any);
    const [contactItems, setContactItems] = useContext(ChatContext);
    const [notifications, setNotifications] = useContext(NotificationContext);
    const [isStateTriggered, setIsStateTriggered] = useState(false);
    const refMsg = useRef() as any;
    let isFirstTime = true;

    const [minimized, setMinimized] = useState(false);

    const [timer, setTimer] = useState(null as any);
    useEffect(() => {
        suggestionFn();
    }, [props]);

    async function suggestionFn() {
        let suggestions = await getSuggestions();
        setSuggestions(suggestions);
    }

    useEffect(() => {
        if (contactItems.length > 0) {
            let selectedItem = contactItems[contactItems.length - 1];
            let selectedWo = selectedItem.orderNumber;
            setSelectedWo(selectedItem);
            startCheckMessage(selectedWo.orderNumber);
            getMessages(selectedWo);
        }

        // setMinimized(false);

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [contactItems]);

    function startCheckMessage(woNumber) {
        if (timer) {
            clearInterval(timer);
        }
        if (woNumber) {
            let x = setInterval(() => {
                getMessages(woNumber, true);
            }, 5000);
            setTimer(x);
        }
    }

    function checkWoNotification(id) {
        let isFound = null;
        notifications?.forEach((n) => {
            if (n.kworkorder == id) {
                isFound = n;
            }
        });

        return isFound;
    }

    async function getMessages(woNumber, isSilent = false) {
        if (!isSilent) {
            setIsLoading(true);
        }
        try {
            let result = await apiService.getReceivedMessages(woNumber);
            console.log("received msg", result);
            setMessages(result.data);

            let notification = checkWoNotification(woNumber) as any;
            if (notification) {
                await apiService.updateNotification(notification.id);
            }

            if (isFirstTime) {
                window.setTimeout(function () {
                    if (refMsg && refMsg.current) {
                        refMsg.current.scrollTop = refMsg.current?.scrollHeight;
                    }
                }, 50);
            }
            isFirstTime = false;
        } catch (error) {
            if (!isSilent) {
                handleErrors(error);
            }
        }

        if (!isSilent) {
            setIsLoading(false);
        }
    }

    if (minimized) {
        return (
            <div
                className="chat-minimized"
                onClick={() => {
                    setMinimized(false);
                }}
            >
                Open Chat
                <span className="ml-2 animate__animated animate__bounce">
                    ({contactItems?.length})
                </span>
            </div>
        );
    }

    return contactItems?.length ? (
        <div className="chat-component">
            {/* <AppLoading isLoading={isLoading} /> */}
            <div className="row no-gutters" style={{ height: "100%" }}>
                <div className="col-auto wo-contact-list ">
                    <ul
                        style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                    >
                        {contactItems.map((item, i) => {
                            let clsName = "";

                            if (item.orderNumber == selectedWo?.orderNumber) {
                                clsName = "selected";
                            }
                            return (
                                <li key={i} className={clsName}>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignContent: "center",
                                        }}
                                    >
                                        <div>
                                            <AppButton
                                                isLoading={isLoading}
                                                onClick={() => {
                                                    let ci = contactItems;
                                                    _.remove(ci, (i: any) => {
                                                        return (
                                                            i.orderNumber ==
                                                            item.orderNumber
                                                        );
                                                    });
                                                    setSelectedWo(ci);
                                                    if (selectedWo) {
                                                        setSelectedWo(
                                                            selectedWo
                                                        );
                                                        startCheckMessage(
                                                            selectedWo.orderNumber
                                                        );
                                                        getMessages(
                                                            selectedWo.orderNumber
                                                        );
                                                    }
                                                }}
                                                style={{ marginTop: 2 }}
                                                className="btn-sm ml-0  p-1 remove-btn"
                                            >
                                                <i
                                                    style={{
                                                        fontSize: 18,
                                                    }}
                                                    className="fas fa-times"
                                                ></i>
                                            </AppButton>
                                        </div>
                                        <div
                                            style={{
                                                flex: 1,
                                                justifyContent: "center",
                                                display: "flex",
                                            }}
                                        >
                                            <Tooltip title="Refresh">
                                                <AppButton
                                                    loadingColor={"black"}
                                                    onClick={() => {
                                                        setSelectedWo(item);
                                                        startCheckMessage(
                                                            item.orderNumber
                                                        );
                                                        getMessages(
                                                            item.orderNumber
                                                        );
                                                    }}
                                                    style={{
                                                        width: "100%",
                                                        backgroundColor:
                                                            "transparent",
                                                        border: "none",
                                                        textAlign: "left",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            width: "100%",
                                                        }}
                                                    >
                                                        {item.orderNumber}
                                                        <AppButton
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            loadingColor={
                                                                "black"
                                                            }
                                                            style={{
                                                                backgroundColor:
                                                                    "transparent",
                                                                border: "none",
                                                                textAlign:
                                                                    "left",
                                                                fontWeight:
                                                                    "bold",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    fontSize: 18,
                                                                }}
                                                                className="fas fa-sync"
                                                            ></i>
                                                        </AppButton>
                                                    </div>
                                                </AppButton>
                                            </Tooltip>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="col msg-right">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 0,
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            {/* {props.contactItems.slice(0, 3).map((item, i) => {
                                return (
                                    <div key={i}>
                                        <div className="wo-title-item">
                                            {item.orderNumber}
                                        </div>
                                    </div>
                                );
                            })}

                            {props.contactItems.length > 3 ? (
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        marginTop: 8,
                                    }}
                                >
                                    More...
                                </div>
                            ) : (
                                <Fragment />
                            )} */}
                        </div>
                        <div className="row no-gutters align-items-center justify-content-end">
                            <div className="col-auto order-sm-2">
                                <button
                                    onClick={() => {
                                        // setContactItems([]);
                                        // setIsStateTriggered(false);
                                        // props.onClose();
                                        setMinimized(true);
                                    }}
                                    className="btn btn-close "
                                >
                                    <i className="fas fa-window-maximize mr-1"></i>
                                    Minimize
                                </button>

                                <button
                                    onClick={() => {
                                        setContactItems([]);
                                        setIsStateTriggered(false);
                                        // props.onClose();
                                    }}
                                    className="btn btn-close "
                                >
                                    <i className="fas fa-times mr-1"></i>
                                    Close
                                </button>
                            </div>
                            <div className="col-auto order-sm-1">
                                {/* {!isStateTriggered ? (
                                    <AppButton
                                        isLoading={isLoading}
                                        onClick={() => {
                                            sendCurrentStatusNotification();
                                        }}
                                        style={{
                                            margin: "5px",
                                            padding: "10px",
                                            backgroundColor: "#607d8b",
                                        }}
                                        className="btn btn-primary"
                                    >
                                        Trigger Current Status Message(s)
                                    </AppButton>
                                ) : (
                                    <span className="triggered-note">
                                        Triggered!
                                    </span>
                                )} */}
                            </div>
                        </div>
                    </div>
                    {getMessageUI()}
                    <div>
                        <div className="msg-send-wrap">
                            <div className="editor-wrap">
                                <Editor
                                    onPressEnter={() => {
                                        handleSend();
                                    }}
                                    initValue={""}
                                    suggestions={suggestions}
                                    id={"chatbox"}
                                    hideToolbar={true}
                                    quilVar={(quill) => {
                                        setTextQuill(quill);
                                    }}
                                />
                            </div>
                            <AppButton
                                loadingColor={"#274acd"}
                                isLoading={isLoading}
                                onClick={() => {
                                    handleSend();
                                }}
                                className="btn btn-primary sent"
                            >
                                <i className="fas fa-paper-plane mr-1"></i>
                                Send
                            </AppButton>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <Fragment />
    );

    async function sendCurrentStatusNotification() {
        console.log(contactItems);

        let data = contactItems.map((d) => {
            return {
                workOrders: d.orderNumber,
                MessageId: d.lastStatus.id,
            };
        });

        setIsLoading(true);
        try {
            let result = await apiService.sendCurrentStatusNotification({
                WorkOrders: data,
            });
            setIsStateTriggered(true);
            console.log(result);
            showNotification("Current Status Message Triggered!", "success");
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    function getMessageUI() {
        return (
            <div ref={refMsg} className="msg-wrap">
                {messages.map((message, i) => {
                    let isReceived = true;
                    if (message.messageType == "SEND") {
                        isReceived = false;
                    }
                    return (
                        <Fragment key={i}>
                            <div
                                className={
                                    isReceived ? "received-msg" : "send-msg"
                                }
                            >
                                <small className="badge p-0">
                                    {isReceived ? "Received from:" : "Sent to:"}
                                </small>
                                <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {message.notifyPersonName}
                                </span>

                                <span style={{ width: "50%", marginLeft: 5 }}>
                                    {message.emailSuccess ? (
                                        <span>
                                            <i className="fas fa-envelope-open-text mr-1"></i>
                                        </span>
                                    ) : (
                                        <Fragment />
                                    )}
                                    {message.textSuccess ? (
                                        <span>
                                            <i className="fas fa-sms mr-1"></i>
                                        </span>
                                    ) : (
                                        <Fragment />
                                    )}
                                </span>

                                <br />

                                {message.templateText ? (
                                    message.templateText
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: message.templateEmailBody,
                                        }}
                                    ></div>
                                )}

                                {/* {message.templateText ? (
                                    message.templateText
                                ) : (
                                    <Fragment />
                                )}
                                <br />
                                {message.templateEmailBody ? (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: message.templateEmailBody,
                                        }}
                                    ></div>
                                ) : (
                                    <Fragment />
                                )} */}

                                <br />
                                <small>
                                    {moment(
                                        message.notificationTriggerTime
                                    ).fromNow()}
                                </small>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        );
    }

    async function handleSend() {
        setIsLoading(true);
        console.log(textQuill?.root?.innerHTML);

        try {
            let data = {
                TemplateEmailHeader: `<p>Custom Message from EBS</p>`,
                TemplateEmailBody: textQuill?.root?.innerHTML,
                TemplateText: textQuill?.root?.innerHTML,
                WorkOrders: [{ workorder: selectedWo.orderNumber }],
            };
            let result = await apiService.sendMessage(data);
            textQuill.setContents("");
            // get msgs
            getMessages(selectedWo.orderNumber);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }
}
