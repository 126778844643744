import _ from "lodash";
import moment from "moment";
import React, {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState,
} from "react";
import ApiService from "../services/ApiService";
import {
    getSuggestions,
    handleErrors,
    showNotification,
} from "../services/HelperMethods";
import { ChatContext, NotificationContext } from "../storage/AppStorage";
import AppButton from "./AppButton";
import AppLoading from "./AppLoading";
import Editor from "./Editor";

export default function ChatHistory(props: {
    contactItems;
    onClose: CallableFunction;
}) {
    // const [props.contactItems, setprops.ContactItems] = useState(props.props.contactItems);

    const [suggestions, setSuggestions] = useState([] as any[]);
    const apiService = new ApiService();
    const [textQuill, setTextQuill] = useState(null as any);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedWo, setSelectedWo] = useState(null as any);
    const [messages, setMessages] = useState([] as any);
    const [notifications, setNotifications] = useContext(NotificationContext);
    const [isStateTriggered, setIsStateTriggered] = useState(false);
    const refMsg = useRef() as any;
    let isFirstTime = true;

    const [minimized, setMinimized] = useState(false);

    console.log(props.contactItems);

    let timer = null as any;
    useEffect(() => {
        suggestionFn();
    }, [props]);

    async function suggestionFn() {
        let suggestions = await getSuggestions();
        setSuggestions(suggestions);
    }

    useEffect(() => {
        // setMessages([]);

        // console.log("f");
        // setprops.ContactItems(_.uniq([...props.contactItems]));

        if (props.contactItems.length > 0) {
            // let contactItem = props.contactItems[0];
            // setSelectedWo(contactItem);
            // getMessages(contactItem.workOrder);
            // setMessages([] as any);
            let selectedItem =
                props.contactItems[props.contactItems.length - 1];
            let selectedWo = selectedItem.workOrder;
            setSelectedWo(selectedItem);
            timer = setInterval(() => {
                getMessages(selectedWo, true);
            }, 5000);
            getMessages(selectedWo);
        } else {
            // if (props.contactItems?.[0]) {
            // }
        }

        setMinimized(false);

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [props.contactItems]);

    function checkWoNotification(id) {
        let isFound = null;
        notifications?.forEach((n) => {
            if (n.kworkorder == id) {
                isFound = n;
            }
        });

        return isFound;
    }

    async function getMessages(woNumber, isSilent = false) {
        if (!isSilent) {
            setIsLoading(true);
        }
        try {
            let result = await apiService.getReceivedMessages(woNumber);
            console.log("received msg", result);
            setMessages(result.data);

            let notification = checkWoNotification(woNumber) as any;
            if (notification) {
                await apiService.updateNotification(notification.id);
            }

            if (isFirstTime) {
                window.setTimeout(function () {
                    if (refMsg && refMsg.current) {
                        refMsg.current.scrollTop = refMsg.current?.scrollHeight;
                    }
                }, 50);
            }
            isFirstTime = false;
        } catch (error) {
            if (!isSilent) {
                handleErrors(error);
            }
        }

        if (!isSilent) {
            setIsLoading(false);
        }
    }

    if (minimized) {
        return (
            <div
                className="chat-minimized"
                onClick={() => {
                    setMinimized(false);
                }}
            >
                Open Chat
            </div>
        );
    }
    return props.contactItems?.length ? (
        <div className="chat-component">
            {/* <AppLoading isLoading={isLoading} /> */}
            <div className="row no-gutters" style={{ height: "100%" }}>
                <div className="col-auto wo-contact-list ">
                    {/* <p
                        style={{
                            fontWeight: 600,
                            color: "#eaeaea",
                            textAlign: "center",
                            fontSize: 13,
                            backgroundColor: "#808080",
                        }}
                        className=" p-2 rounded"
                    >
                        You will send messages to all work orders below!
                    </p> */}
                    <ul
                        style={{
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                    >
                        {props.contactItems.map((item, i) => {
                            let clsName = "";

                            if (item.workOrder == selectedWo?.workOrder) {
                                clsName = "selected";
                            }
                            return (
                                <li
                                    key={i}
                                    className={clsName}
                                    onClick={() => {
                                        setSelectedWo(item);
                                        getMessages(item.workOrder);
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {item.workOrder}

                                        <i
                                            style={{ fontSize: 25 }}
                                            className="fas fa-id-badge"
                                        ></i>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="col msg-right">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginTop: 0,
                        }}
                    >
                        <div style={{ flex: 1 }}>
                            {/* {props.props.contactItems.slice(0, 3).map((item, i) => {
                                return (
                                    <div key={i}>
                                        <div className="wo-title-item">
                                            {item.workOrder}
                                        </div>
                                    </div>
                                );
                            })}

                            {props.props.contactItems.length > 3 ? (
                                <div
                                    style={{
                                        fontSize: 14,
                                        fontWeight: "bold",
                                        marginTop: 8,
                                    }}
                                >
                                    More...
                                </div>
                            ) : (
                                <Fragment />
                            )} */}
                        </div>
                        <div className="row no-gutters align-items-center justify-content-end">
                            <div className="col-auto order-sm-2">
                                {/* <button
                                    onClick={() => {
                                        // setprops.ContactItems([]);
                                        // setIsStateTriggered(false);
                                        // props.onClose();
                                        setMinimized(true);
                                    }}
                                    className="btn btn-close "
                                >
                                    <i className="fas fa-window-maximize mr-1"></i>
                                    Minimize
                                </button> */}

                                <button
                                    onClick={() => {
                                        props.onClose();
                                        setIsStateTriggered(false);
                                        // props.onClose();
                                    }}
                                    className="btn btn-close "
                                >
                                    <i className="fas fa-times mr-1"></i>
                                    Close
                                </button>
                            </div>
                            <div className="col-auto order-sm-1">
                                {/* {!isStateTriggered ? (
                                    <AppButton
                                        isLoading={isLoading}
                                        onClick={() => {
                                            sendCurrentStatusNotification();
                                        }}
                                        style={{
                                            margin: "5px",
                                            padding: "10px",
                                            backgroundColor: "#607d8b",
                                        }}
                                        className="btn btn-primary"
                                    >
                                        Trigger Current Status Message(s)
                                    </AppButton>
                                ) : (
                                    <span className="triggered-note">
                                        Triggered!
                                    </span>
                                )} */}
                            </div>
                        </div>
                    </div>
                    {getMessageUI()}
                    {/* <div>
                        <div className="msg-send-wrap">
                            <div className="editor-wrap">
                                <Editor
                                    onPressEnter={() => {
                                        handleSend();
                                    }}
                                    initValue={""}
                                    suggestions={suggestions}
                                    id={"chatbox"}
                                    hideToolbar={true}
                                    quilVar={(quill) => {
                                        setTextQuill(quill);
                                    }}
                                />
                            </div>
                            <AppButton
                                loadingColor={"#274acd"}
                                isLoading={isLoading}
                                onClick={() => {
                                    handleSend();
                                }}
                                className="btn btn-primary sent"
                            >
                                <i className="fas fa-paper-plane mr-1"></i>
                                Send
                            </AppButton>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    ) : (
        <Fragment />
    );

    function getMessageUI() {
        if (!messages.length && !isLoading) {
            return <div className="no-msg-lbl">No Messages</div>;
        }
        return (
            <div ref={refMsg} className="msg-wrap">
                {messages.map((message, i) => {
                    let isReceived = true;
                    if (message.messageType == "SEND") {
                        isReceived = false;
                    }
                    return (
                        <Fragment key={i}>
                            <div
                                className={
                                    isReceived ? "received-msg" : "send-msg"
                                }
                            >
                                <small className="badge p-0">
                                    {isReceived ? "Received from:" : "Sent to:"}
                                </small>
                                <span style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {message.notifyPersonName}
                                </span>
                                <br />

                                {message.templateText ? (
                                    message.templateText
                                ) : (
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: message.templateEmailBody,
                                        }}
                                    ></div>
                                )}
                                <br />
                                <small>
                                    {moment(
                                        message.notificationTriggerTime
                                    ).fromNow()}
                                </small>
                            </div>
                        </Fragment>
                    );
                })}
            </div>
        );
    }

    // async function handleSend() {
    //     setIsLoading(true);
    //     console.log(textQuill?.root?.innerHTML);

    //     let orderNumbers = props.contactItems.map((item) => {
    //         return { workorder: item.orderNumber };
    //     });

    //     try {
    //         let data = {
    //             TemplateEmailHeader: `<p>Custom Message from EBS</p>`,
    //             TemplateEmailBody: textQuill?.root?.innerHTML,
    //             TemplateText: textQuill?.root?.innerHTML,
    //             WorkOrders: [{ workorder: selectedWo.orderNumber }],
    //         };
    //         let result = await apiService.sendMessage(data);
    //         textQuill.setContents("");
    //         // get msgs
    //         getMessages(selectedWo.orderNumber);
    //     } catch (error) {
    //         handleErrors(error);
    //     }
    //     setIsLoading(false);
    // }
}
