import moment from "moment";
import { toast } from "react-toastify";
import ApiService from "./ApiService";

export function handleErrors(err) {
    if (err?.response?.data?.error && !err?.response?.data?.error?.errorType) {
        showNotification(err?.response?.data?.error?.message, "error");
    } else if (err?.response?.data?.errors) {
        for (const [key, value] of Object.entries(err.response?.data?.errors)) {
            let title = key as any;
            let message = (value as any)[0] as any;
            showNotification(message, "error");
        }
    } else if (err?.response?.data?.error?.errorType) {
        showNotification(err?.response?.data?.error?.errorMessage, "error");
    } else if (err?.response?.status == 401 || err?.response?.status == 402) {
        showNotification(
            "Your auth session expired!, Please Login again",
            "error"
        );
    } else {
        showNotification("Network error! try again", "error");
    }

    console.log(err, err.response);
}

export async function getSuggestions() {
    // if (suggestions.length) {
    //     return;
    // }
    const apiService = new ApiService();
    let mapping = (await apiService.getLabelMapping()) as any;

    console.log("suggestion", mapping);

    let mappingArr = [] as any[];
    mappingArr.push({
        id: 1,
        value: mapping.data.customerName
            ? mapping.data.customerName
            : "Customer Name" + ">",
    });
    mappingArr.push({
        id: 2,
        value: mapping.data.workOrderNumber
            ? mapping.data.workOrderNumber
            : "Work Order Number" + ">",
    });
    mappingArr.push({
        id: 3,
        value: mapping.data.customerNumber
            ? mapping.data.customerNumber
            : "Customer Number" + ">",
    });
    mappingArr.push({
        id: 4,
        value: mapping.data.make ? mapping.data.make : "Make" + ">",
    });
    mappingArr.push({
        id: 5,
        value: mapping.data.model ? mapping.data.model : "Model" + ">",
    });
    mappingArr.push({
        id: 6,
        value: mapping.data.date ? mapping.data.date : "Date" + ">",
    });
    mappingArr.push({
        id: 2,
        value: mapping.data.serviceCallBackNumber
            ? mapping.data.serviceCallBackNumber
            : "Service Callback Number" + ">",
    });
    mappingArr.push({
        id: 7,
        value: mapping.data.dealerName
            ? mapping.data.dealerName
            : "Dealer Name" + ">",
    });
    mappingArr.push({
        id: 8,
        value: mapping.data.equipmentId
            ? mapping.data.equipmentId
            : "Equipment ID" + ">",
    });
    mappingArr.push({
        id: 9,
        value: mapping.data.serialNumber
            ? mapping.data.serialNumber
            : "Serial Number" + ">",
    });
    mappingArr.push({
        id: 10,
        value: mapping.data.techName
            ? mapping.data.techName
            : "Tech Name" + ">",
    });
    mappingArr.push({
        id: 10,
        value: mapping.data.branch ? mapping.data.branch : "Branch" + ">",
    });
    return mappingArr;
}

export function randomColor() {
    return Math.floor(Math.random() * 16777215).toString(16);
}

export function saveToken(token) {
    localStorage.setItem("ebsToken", token);
}

export function getToken() {
    return localStorage.getItem("ebsToken");
}
export function showNotification(message, type: "success" | "error" | "info") {
    let tst =
        type == "success"
            ? toast.success
            : type == "error"
            ? toast.error
            : toast.info;
    tst(message);
}

export function spaceAfterCapital(s: string) {
    return s.replace(/([A-Z])/g, " $1").trim();
}
