import React, { Fragment } from "react";
import ClipLoader from "react-spinners/ClipLoader";

export default function AppLoading(props: { isLoading?: boolean }) {
    if (!props.isLoading) {
        return <Fragment />;
    }
    return (
        <div className="app-loading">
            <ClipLoader size={20} color={"black"} />
        </div>
    );
}
