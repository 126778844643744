import React from "react";
import Breadcrumb from "../components/Breadcrumb";

export default function () {
    return (
        <div className="content">
            <Breadcrumb path="UserProfile" />
        </div>
    );
}
