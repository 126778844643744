import React from "react";
import Slider from "react-slick";

export default function AppPagination(props: {
    items;
    rowsPerPage;
    currentPage;
    onChangePage;
}) {
    let pages = [] as any[];
    for (let i = 0; i < props.items.length / props.rowsPerPage; i++) {
        let additionalClass = "";
        if (props.currentPage == i) {
            additionalClass += " current";
        }
        pages.push(
            <button
                onClick={() => {
                    props.onChangePage(i);
                }}
                className={"pagination-buttons" + additionalClass}
            >
                {i + 1}
            </button>
        );
    }
    let calcSize = pages.length < 5 ? pages.length : 5;
    return (
        <div
            style={{
                width: 40 * calcSize,
                marginRight: 30,
                marginLeft: 30,
            }}
        >
            <Slider
                dots={false}
                slidesToScroll={5}
                infinite={false}
                slidesToShow={calcSize}
                speed={100}
            >
                {pages.map((v, i) => {
                    return <div key={i}>{v}</div>;
                })}
            </Slider>
        </div>
    );
}
