import React from "react";
import ClipLoader from "react-spinners/ClipLoader";
export default function AppButton(props: {
    isLoading?: boolean;
    onClick?: CallableFunction;
    children?: any;
    style?: React.CSSProperties;
    className?: string;
    loadingColor?: string;
}) {
    return (
        <button
            style={props.style}
            className={props.className}
            disabled={props.isLoading}
            onClick={(e) => {
                e.preventDefault();
                props.onClick?.();
            }}
        >
            {props.isLoading ? (
                <ClipLoader
                    size={20}
                    color={props.loadingColor ? props.loadingColor : "white"}
                />
            ) : (
                props.children
            )}
        </button>
    );
}
