import React, { useEffect, useState } from "react";
import AppLoading from "../components/AppLoading";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import { handleErrors, randomColor } from "../services/HelperMethods";
import { ChromePicker, TwitterPicker } from "react-color";
import ColorPicker from "react-color-picker";

import { Fragment } from "react";
import Editor from "../components/Editor";
import AppButton from "../components/AppButton";
import Dragula from "react-dragula";
import { Checkbox } from "@material-ui/core";

import "react-color-picker/index.css";

export default function () {
    const apiService = new ApiService();
    const [serviceMessages, setServiceMessages] = useState([] as any[]);
    const [isLoading, setIsLoading] = useState(false);
    const [suggestions, setSuggestions] = useState([] as any[]);
    const [refresh, setRefresh] = useState([] as any[]);

    useEffect(() => {
        if (refresh.length) {
            let sm = [...serviceMessages];
            refresh?.forEach((msg, i) => {
                sm.forEach((sm) => {
                    if (sm.id == msg) {
                        sm.orderNumber = i;
                    }
                });
            });
            sendOrder();
            console.log(sm);
        }
        // setServiceMessages(sm);
    }, [refresh]);

    async function sendOrder() {
        setIsLoading(true);

        try {
            let data = serviceMessages.map((item) => {
                return {
                    serviceMessageId: item.id,
                    orderId: item.orderNumber,
                };
            });
            let result = await apiService.sendMessageOrders({
                serviceMessages: data,
            });
            console.log(result);
        } catch (error) {
            handleErrors(error);
        }
        setRefresh([]);
        setIsLoading(false);
    }

    const dragulaDecorator = (componentBackingInstance) => {
        if (componentBackingInstance) {
            let options = {
                moves: function (el, source, handle, sibling) {
                    var x = handle.classList.contains("draggable");
                    return x;
                },
            };
            let drake = Dragula([componentBackingInstance], options);
            drake.on("drop", (el, target) => {
                let $ = (window as any).$;

                let msgIds = [] as any[];
                $(target)
                    .find(".msg-row")
                    .each((order, val) => {
                        let msgId = $(val).data("id");
                        msgIds.push(msgId);
                    });

                setRefresh(msgIds);
            });
        }
    };

    useEffect(() => {
        getServiceMessages();
    }, []);

    async function getServiceMessages() {
        setIsLoading(true);
        try {
            let mapping = (await apiService.getLabelMapping()) as any;
            console.log("mapping", mapping);

            let mappingArr = [] as any[];
            mappingArr.push({ id: 1, value: mapping.data.customerName + ">" });
            mappingArr.push({
                id: 2,
                value: mapping.data.workOrderNumber + ">",
            });
            mappingArr.push({
                id: 3,
                value: mapping.data.customerNumber + ">",
            });
            mappingArr.push({ id: 4, value: mapping.data.make + ">" });
            mappingArr.push({ id: 5, value: mapping.data.model + ">" });
            mappingArr.push({ id: 6, value: mapping.data.date + ">" });
            mappingArr.push({
                id: 2,
                value: mapping.data.serviceCallBackNumber + ">",
            });
            mappingArr.push({ id: 7, value: mapping.data.dealerName + ">" });
            mappingArr.push({ id: 8, value: mapping.data.equipmentId + ">" });
            mappingArr.push({ id: 9, value: mapping.data.serialNumber + ">" });
            mappingArr.push({ id: 10, value: mapping.data.techName + ">" });
            setSuggestions(mappingArr);

            let result = await apiService.getServiceMessages();
            console.log("msg", result.data);
            setServiceMessages(result.data.serviceMessages);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            // const data = [...serviceMessages];
            // const item = data.splice(fromIndex, 1)[0];
            // data.splice(toIndex, 0, item);
            // setServiceMessages(data);
        },
        nodeSelector: "li",
        handleSelector: "a",
    };

    return (
        <div className="content messages-page">
            <AppLoading isLoading={isLoading} />

            <div className="row">
                {/* <div className="col-auto">
                    <Breadcrumb path="Change Order" />
                    <div className="change-order-items-wrap mt-4">
                        <RLDD
                            items={serviceMessages}
                            itemRenderer={(msg: any) => {
                                return (
                                    <div className="item">
                                        <div className="reorder-icon">
                                            <i className="fas fa-bars"></i>
                                        </div>
                                        {msg.statusMessage}
                                    </div>
                                );
                            }}
                            onChange={(items) => {
                                items.forEach((item: any, i) => {
                                    item.messageOrder = i + 1;
                                });
                                setServiceMessages(items);
                            }}
                        />
                    </div>
                </div> */}
                <div className="col">
                    <div className="row ">
                        <div className="col">
                            <Breadcrumb path="Messages" />
                        </div>
                        <div className="col-auto">
                            {/* {refresh.length ? (
                                <button
                                    className="btn btn-primary btn-sm"
                                    onClick={() => {
                                        sendOrder();
                                    }}
                                >
                                    Save order
                                </button>
                            ) : (
                                <Fragment />
                            )} */}
                        </div>
                        <div className="col-auto">
                            <button
                                onClick={() => {
                                    let existingMsg = [...serviceMessages];
                                    existingMsg.unshift({
                                        active: true,
                                        createBy: 1,
                                        createDate: "",
                                        displayColor: randomColor(),
                                        emailBody: "",
                                        emailSubject: "",
                                        id: (existingMsg.length + 1) * -1,
                                        messageOrder: 2,
                                        messageText: "",
                                        statusMessage: "",
                                    });
                                    console.log(existingMsg);

                                    setServiceMessages(existingMsg);
                                }}
                                className="btn btn-secondary btn-sm"
                            >
                                Add new
                            </button>
                        </div>
                    </div>
                    <div>
                        <div style={{ padding: 0 }} ref={dragulaDecorator}>
                            {serviceMessages.map((msg, i) => {
                                return (
                                    <div
                                        key={msg.id}
                                        data-id={msg.id}
                                        className="msg-row"
                                    >
                                        {/* <a href="#">Drag</a> */}
                                        <ServiceMessageRow
                                            onRefresh={() => {
                                                setServiceMessages([
                                                    ...serviceMessages,
                                                ]);
                                            }}
                                            message={msg}
                                            suggestions={suggestions}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
function ServiceMessageRow(props: { message; suggestions; onRefresh }) {
    const [showContent, setShowContent] = useState(false);
    const [statusMessage, setStatusMessage] = useState("");
    const apiService = new ApiService();
    const [isWorking, setIsWorking] = useState(false);
    const [textBody, setTextBody] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [emailSubjectQuill, setEmailQuill] = useState(null as any);
    const [backgroundColor, setBackgroundColor] = useState("");
    const [messageQuill, setMessageQuill] = useState(null as any);
    const [textQuill, setTextQuill] = useState(null as any);

    useEffect(() => {
        setStatusMessage(props.message.statusMessage);
        if (props.message.emailSubject) {
            setEmailQuill(props.message.emailSubject);
        }
        if (props.message.displayColor) {
            setBackgroundColor(props.message.displayColor);
        }
    }, []);

    return (
        <div>
            <div
                className="header"
                onClick={() => {
                    setShowContent(!showContent);
                }}
            >
                <div className="reorder-icon draggable">
                    <i className="fas fa-bars draggable"></i>
                </div>
                <div className="msg-title">
                    <input
                        className="msg-title-input"
                        onChange={(e) => {
                            setStatusMessage(e.target.value);
                        }}
                        value={statusMessage}
                    />
                </div>

                <div
                    style={{
                        backgroundColor: "#" + backgroundColor,
                        height: "100%",
                    }}
                ></div>
                <div>
                    <Checkbox
                        checked={props.message.auto}
                        value={props.message.auto}
                        onChange={(i, val) => {
                            props.message.auto = val;
                            toggleAuto(props.message.id);
                            setShowContent(false);
                            props.onRefresh();
                        }}
                    />
                    Auto
                </div>
                <div className="toggle-button">
                    {showContent ? (
                        <i className="fas fa-caret-down"></i>
                    ) : (
                        <i className="fas fa-caret-right"></i>
                    )}
                </div>
            </div>

            {showContent ? (
                <div className="input-grid">
                    <div>
                        <div className="title">Email Template</div>
                        <div className="subject mt-3">
                            <label>Subject</label>
                            <Editor
                                hideToolbar={true}
                                initValue={props.message.emailSubject}
                                suggestions={props.suggestions}
                                id={"editor-" + props.message.id + "e"}
                                quilVar={(quill) => {
                                    setEmailQuill(quill);
                                }}
                            />
                        </div>
                        <div>
                            <label>Message</label>
                            <Editor
                                initValue={props.message.emailBody}
                                suggestions={props.suggestions}
                                id={"editor-" + props.message.id}
                                quilVar={(quill) => {
                                    setMessageQuill(quill);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="title">Text Template</div>
                        <Editor
                            initValue={props.message.messageText}
                            suggestions={props.suggestions}
                            id={"editor-sms-" + props.message.id}
                            hideToolbar={true}
                            quilVar={(quill) => {
                                setTextQuill(quill);
                            }}
                        />
                    </div>
                    <div>
                        <div onClick={() => {}} className="picked-color"></div>
                        <div className="picker">
                            {/* <ColorPicker onDrag={() => {}} /> */}
                            <ChromePicker
                                disableAlpha={true}
                                color={backgroundColor}
                                onChange={(color) => {
                                    let c = color.hex.replace("#", "");
                                    setBackgroundColor(c);
                                }}
                            />
                            <TwitterPicker
                                color={props.message.displayColor}
                                onChange={(color) => {
                                    let c = color.hex.replace("#", "");
                                    setBackgroundColor(c);
                                }}
                                colors={[
                                    "#FF6900",
                                    "#FF6900",
                                    "#7BDCB5",
                                    "#00D084",
                                    "#8ED1FC",
                                    "#0693E3",
                                    "#ABB8C3",
                                    "#EB144C",
                                    "#F78DA7",
                                    "#9900EF",
                                    "#5E4352",
                                    "#1E3888",
                                    "#FFAD69",
                                    "#9C3848",
                                ]}
                            />
                        </div>
                    </div>
                    <div />
                    <div />
                    <div
                        style={{
                            width: "100%",
                            textAlign: "right",
                            float: "right",
                        }}
                    >
                        {props.message.deleted ? (
                            <AppButton
                                isLoading={isWorking}
                                className="btn btn-primary"
                                style={{
                                    backgroundColor: "#4CAF50",
                                    marginRight: 15,
                                }}
                                onClick={() => {
                                    handleActive(props.message.id);
                                    props.message.deleted = false;
                                }}
                            >
                                Active Again
                            </AppButton>
                        ) : (
                            <AppButton
                                isLoading={isWorking}
                                className="btn btn-primary"
                                style={{
                                    backgroundColor: "red",
                                    marginRight: 15,
                                }}
                                onClick={() => {
                                    handleDelete(props.message.id);
                                    props.message.deleted = true;
                                }}
                            >
                                Delete
                            </AppButton>
                        )}

                        <AppButton
                            isLoading={isWorking}
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </AppButton>
                    </div>
                </div>
            ) : (
                <Fragment />
            )}
        </div>
    );

    async function handleActive(id) {
        setIsWorking(true);
        try {
            let result = await apiService.activeServiceMessage(id);
            console.log(result);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    async function handleDelete(id) {
        setIsWorking(true);
        try {
            let result = await apiService.deleteServiceMessage(id);
            console.log(result);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    async function toggleAuto(id) {
        setIsWorking(true);
        try {
            let result = await apiService.toggleAuto(id);
            console.log(result);
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }

    async function handleSave() {
        setIsWorking(true);
        try {
            let data = {
                Id: props.message.id,
                StatusMessage: statusMessage,
                MessageOrder: props.message.messageOrder,
                DisplayColor: backgroundColor,
                EmailSubject: emailSubjectQuill.root.innerHTML,
                EmailBody: messageQuill.root.innerHTML,
                MessageText: textQuill.root.innerHTML,
                Active: true,
                Auto: props.message.auto,
                CreateDate: new Date(),
            };
            if (props.message.id < 0) {
                let result = await apiService.addMessageTemplate(data);
                window.location.reload();
            } else {
                let result = await apiService.updateMessageTemplate(data);
            }
        } catch (error) {
            handleErrors(error);
        }
        setIsWorking(false);
    }
}
