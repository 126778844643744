import axios from "axios";
import moment from "moment";
import { getToken, saveToken } from "./HelperMethods";

export default class ApiService {
    async refreshToken() {
        const jwt = getToken();

        let ax = axios.create({
            baseURL: this.getBaseURI(),
            timeout: 990000,
            headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });

        let result = (await ax.get("/v1/api/auth/GetNewToken/")) as any;
        saveToken(result.data.token);
    }

    async sendCurrentStatusNotification(data) {
        const ax = await this.ax();
        return await ax.post(
            "/v1/api/notification/SendCurrentStatusNotification/",
            data
        );
    }

    async updateNotification(id: any) {
        const ax = await this.ax();
        return await ax.post(
            "/v1/api/notification/UpdateNotificationView/" + id
        );
    }
    async getNotification() {
        const ax = await this.ax();
        return await ax.get("/v1/api/notification/GetNewNotifications");
    }
    async checkNotificationSend(id: any) {
        const ax = await this.ax();
        return await ax.get("/v1/api/WorkOrder/GetWorkOrder/" + id);
    }
    async activeServiceMessage(id: any) {
        const ax = await this.ax();
        return await ax.put(
            "/v1/api/ServiceMessage/ActiveServiceMessage/" + id
        );
    }
    async deleteServiceMessage(id: number) {
        const ax = await this.ax();
        return await ax.put(
            "/v1/api/ServiceMessage/DeleteServiceMessage/" + id
        );
    }
    async toggleAuto(id: any) {
        const ax = await this.ax();
        return await ax.put("/v1/api/ServiceMessage/ChangeAuto/" + id);
    }
    async sendMessageOrders(data) {
        const ax = await this.ax();
        return await ax.put(
            "/v1/api/ServiceMsgTemplate/UpdateMessageTemplateOrder",
            data
        );
    }
    async sendMessage(data) {
        const ax = await this.ax();
        return await ax.post(
            "/v1/api/Notification/SendCustomNotification",
            data
        );
    }
    async changeWorkOrder(data) {
        const ax = await this.ax();
        return await ax.post("/v1/api/WorkOrder/ChangeWorkOrder", data);
    }
    async getStatus() {
        const ax = await this.ax();
        return await ax.get("/v1/api/ServiceMessage/GetServiceMessages");
    }
    async auth(data) {
        const ax = await this.ax();
        return await ax.post("/v1/api/Auth", data);
    }

    async getWorkOrder() {
        const ax = await this.ax();
        return await ax.get("/v1/api/WorkOrder/GetWorkOrders");
    }

    async getWorkOrderHistory() {
        const ax = await this.ax();
        return await ax.get("/v1/api/OrderHistory/GetOrderHistory");
    }

    async updateMessageTemplate(data) {
        const ax = await this.ax();
        return await ax.put(
            "/v1/api/ServiceMsgTemplate/updateMessageTemplate",
            data
        );
    }

    async addMessageTemplate(data) {
        const ax = await this.ax();
        return await ax.post(
            "/v1/api/ServiceMsgTemplate/AddMessageTemplate",
            data
        );
    }

    async getLabelMapping() {
        const ax = await this.ax();
        return await ax.get("/v1/api/LabelMapping/GetLabelMappings");
    }

    async updateLabelMapping(data) {
        const ax = await this.ax();
        return await ax.post("/v1/api/LabelMapping/UpdateLabelMappings", data);
    }

    async cancelNotification(id: number) {
        const ax = await this.ax();
        return await ax.post(
            "/v1/api/WorkOrder/CancelWorkOrderNotification/" + id
        );
    }

    async getReceivedMessages(workOrderNumber: number) {
        const ax = await this.ax();
        return await ax.get(
            "/v1/api/WorkOrder/GetWorkOrderMessages/" + workOrderNumber
        );
    }

    async getServiceMessages() {
        const ax = await this.ax();
        return await ax.get("/v1/api/ServiceMessage/GetServiceMessages/");
    }

    async ax() {
        let baseUlr = this.getBaseURI();

        // await this.refreshTokenIfRequired();

        const jwt = getToken();

        return axios.create({
            baseURL: baseUlr,
            timeout: 990000,
            headers: {
                Authorization: `Bearer ${jwt}`,
                "Content-Type": "application/json",
            },
        });
    }

    getBaseURI() {
        let baseUlr = "";
        if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
            baseUlr = "https://ebsdev.api.esyntaxis.com/"; //https://192.168.8.100:45455/";
        } else {
            baseUlr = process.env.REACT_APP_BASE_URL!;
        }

        return baseUlr;
    }

    // async refreshTokenIfRequired() {
    //     let jwt = getToken();
    //     let isRemember = localStorage.getItem("isRememberPW");
    //     if (jwt && isRemember == "true") {
    //         try {
    //             var lastTokenGenTime = localStorage.getItem("lastTokenGenTime");
    //             if (
    //                 !lastTokenGenTime ||
    //                 moment(lastTokenGenTime).add(3, "h").isBefore(moment())
    //             ) {
    //                 let data = await new ApiService().refreshToken();
    //                 localStorage.setItem(
    //                     "lastTokenGenTime",
    //                     JSON.stringify(new Date())
    //                 );
    //                 saveToken(data.data.token);
    //                 console.log("Token refreshed");
    //             }
    //         } catch (error) {}
    //     }
    // }
}
