import moment from "moment";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ApiService from "../services/ApiService";
import { handleErrors, saveToken } from "../services/HelperMethods";
import { ChatContext, NotificationContext } from "../storage/AppStorage";
import Modal from "react-modal";
import AppButton from "./AppButton";

export default function () {
    const history = useHistory();
    const apiService = new ApiService();
    const [notifications, setNotifications] = useContext(NotificationContext);
    const [showNotification, setShowNotification] = useState(false);
    const [contactItems, setContactItems] = useContext(ChatContext);
    const [isShowFullSidebar, setIsShowFullSidebar] = useState(false);
    // const [showModel, setShowModel] = useState(false);
    const [branchName, setBranchName] = useState("");
    let timer = null as any;
    let refreshTokenTimer = null as any;
    useEffect(() => {
        let branch = localStorage.getItem("branch");
        if (branch) {
            setBranchName(branch);
        }
        timer = setInterval(() => {
            getResult();
        }, 5000);

        refreshTokenTimer = setInterval(async () => {
            await apiService.refreshToken();
        }, 600000);

        inactivityTime();

        return () => {
            clearInterval(timer);
            clearInterval(refreshTokenTimer);
        };
    }, []);

    var inactivityTime = function () {
        var time;
        window.onload = resetTimer;
        // DOM Events
        document.onmousemove = resetTimer;
        document.onkeypress = resetTimer;

        function logout() {
            saveToken("");
            alert("You are been logged out due to inactivity");
        }

        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, 3600000);
        }
    };

    // async function checkLoginTimeout() {
    //     let lastLoginTime = localStorage.getItem("lastLoginTime");
    //     let momentLastLoginTime = moment(lastLoginTime);
    //     if (momentLastLoginTime.add(50, "m").isBefore()) {
    //         let rememberOn = localStorage.getItem("isRememberPassword");
    //         if (rememberOn == "false") {
    //             if (!isInactive) {
    //                 await apiService.refreshToken();
    //             }
    //         }
    //     }
    // }

    useEffect(() => {
        let isShowFullSidebar = localStorage.getItem("showFullSidebar");
        if (isShowFullSidebar == "false") {
            handleSidebarToggle();
        }
        console.log(isShowFullSidebar);
        setIsShowFullSidebar(
            isShowFullSidebar == "true" || isShowFullSidebar == null
        );
    }, []);

    async function getResult() {
        try {
            let result = await apiService.getNotification();
            setNotifications(result.data);
        } catch (error) {
            // handleErrors(error);
        }
    }

    function handleSidebarToggle() {
        const $ = (window as any).jQuery;
        $(".sidebar").toggleClass("small");
        $(".main-panel").toggleClass("small");
        let hasClass = $(".sidebar").hasClass("small");
        setIsShowFullSidebar(hasClass);
        localStorage.setItem("showFullSidebar", hasClass);
    }
    return (
        <div
            className="toolbar-wrap"
            style={{ width: "100%", display: "inline-block" }}
        >
            {/* <Modal isOpen={showModel} onRequestClose={() => {}}>
                <h3>Your Login session is about to expire in 10 min</h3>
                <p>Please click below button to extend your login session</p>
                <p>
                    If see this constantly, check remember me at login screen so
                    your session will not expire until you logged out
                </p>
                <AppButton
                    className="extend-session-btn"
                    onClick={async () => {
                        localStorage.setItem(
                            "lastLoginTime",
                            new Date().toString()
                        );
                        setShowModel(false);
                    }}
                >
                    Extend login session
                </AppButton>
                <AppButton
                    className="close-modal-button"
                    onClick={() => {
                        setShowModel(false);
                        localStorage.setItem(
                            "lastLoginTime",
                            new Date().toString()
                        );
                    }}
                >
                    Close without extend
                </AppButton>
            </Modal> */}

            <button
                className="open-menu"
                onClick={() => {
                    const $ = (window as any).jQuery;
                    $(".sidebar").addClass("open");
                }}
            >
                <i className="fas fa-bars"></i>
            </button>
            <button
                className="small-open-menu"
                onClick={() => {
                    handleSidebarToggle();
                }}
            >
                {isShowFullSidebar ? (
                    <i className="fas fa-angle-right"></i>
                ) : (
                    <i className="fas fa-angle-left"></i>
                )}
            </button>

            <span className="branch-name">
                <small>Branch: </small>
                {branchName}
            </span>

            <button
                onClick={() => {
                    localStorage.clear();
                    history.push("/start/login");
                }}
                className="btn btn-link"
                style={{ float: "right", marginRight: 25 }}
            >
                <i className="fas fa-power-off"></i> Logout
            </button>
            <div
                onClick={() => {
                    let $ = (window as any).jQuery;
                    // $(window).click(function () {
                    //     $(".notification-wrap").removeClass("open");
                    // });

                    setShowNotification(!showNotification);
                }}
                className="cursor-pointer"
                style={{
                    float: "right",
                    marginRight: 10,
                    marginTop: 11,
                    fontSize: 18,
                }}
            >
                <i className="far fa-envelope"></i>

                {notifications.length ? (
                    <div className="notification-tick animate__animated animate__bounce">
                        {notifications.length}
                    </div>
                ) : (
                    <Fragment />
                )}

                <div
                    className={
                        showNotification && notifications.length
                            ? "notification-wrap open"
                            : "notification-wrap"
                    }
                >
                    <div className="title">Notifications</div>
                    <button
                        className="title-close"
                        onClick={() => {
                            setShowNotification(false);
                        }}
                    >
                        Close
                    </button>
                    <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                        {notifications.map((notification, i) => {
                            return (
                                <div
                                    onClick={() => {
                                        notificationClick(notification);
                                    }}
                                    className="notification-item"
                                    key={i}
                                >
                                    <div className="order-number">
                                        Order Number: {notification.kworkorder}
                                    </div>
                                    <div>
                                        Date:{" "}
                                        {moment
                                            .utc(notification.createDate)
                                            .local()
                                            .format("MM/DD/YYYY")}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );

    async function notificationClick(notification) {
        setContactItems([{ orderNumber: notification.kworkorder }]);
        // try {
        //     let result = await apiService.updateNotification(notification.id);
        //     await getResult();
        // } catch (error) {
        //     handleErrors(error);
        // }
    }
}
