import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import AppButton from "../components/AppButton";
import Breadcrumb from "../components/Breadcrumb";
import ApiService from "../services/ApiService";
import {
    handleErrors,
    showNotification,
    spaceAfterCapital,
} from "../services/HelperMethods";

export default function () {
    const apiService = new ApiService();

    const [isLoading, setIsLoading] = useState(false);
    const [id, setId] = useState("");
    const [branch, setBranch] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [workOrderNumber, setWorkOrderNumber] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [make, setMake] = useState("");
    const [model, setModel] = useState("");
    const [date, setDate] = useState("");
    const [serviceCallBackNumber, setServiceCallBackNumber] = useState("");
    const [equipmentId, setEquipmentId] = useState("");
    const [dealerName, setDealerName] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [techName, setTechName] = useState("");

    useEffect(() => {
        getLabelMapping();
    }, []);

    async function getLabelMapping() {
        setIsLoading(true);
        try {
            let result = (await apiService.getLabelMapping()) as any;
            result = result.data;
            console.log("mf", result);
            setId(result.id);
            setCustomerName(result.customerName);
            setWorkOrderNumber(result.workOrderNumber);
            setCustomerNumber(result.customerNumber);
            setMake(result.make);
            setModel(result.model);
            setDate(result.date);
            setServiceCallBackNumber(result.serviceCallBackNumber);
            setDealerName(result.dealerName);
            setEquipmentId(result.equipmentId);
            setSerialNumber(result.serialNumber);
            setTechName(result.techName);
            setBranch(result.branch);
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }

    return (
        <div className="content">
            <div className="merge-fields">
                <Breadcrumb path="Mergefields" />

                <p className="instruction p">
                    This page allows you to customize what you want your merge
                    fields to be. Simply enter what literal you would like to
                    use for each category below.
                </p>
                <p className="instruction p">{`To use merge fields, use <> brackets around the literal you defined. When defining your own literal, please do not include the brackets.`}</p>

                <p className="instruction p">{`For example: Thank You <Customer Name> for your business.`}</p>

                <p className="instruction p">{`If you do not define your own custom merge field, the default will be the title. For example: <Customer Name> <Branch>, or <Service Call Back Number>.`}</p>

                <div className="container mt-4">
                    <div className="grid">
                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Customer Name{" "}
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={customerName}
                                onChange={(e) => {
                                    setCustomerName(
                                        processValue(e.target.value)
                                    );
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>
                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Work Order Number
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={workOrderNumber}
                                onChange={(e) => {
                                    setWorkOrderNumber(
                                        processValue(e.target.value)
                                    );
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>
                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Customer Number
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={customerNumber}
                                onChange={(e) => {
                                    setCustomerNumber(
                                        processValue(e.target.value)
                                    );
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">Make</div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={make}
                                onChange={(e) => {
                                    setMake(processValue(e.target.value));
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Service Callback Number
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={serviceCallBackNumber}
                                onChange={(e) => {
                                    setServiceCallBackNumber(
                                        processValue(e.target.value)
                                    );
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>
                        <div className="input-wrapper">
                            <div className="merge-field-label">Model</div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={model}
                                onChange={(e) => {
                                    setModel(processValue(e.target.value));
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">Date</div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={date}
                                onChange={(e) => {
                                    setDate(processValue(e.target.value));
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Equipment ID
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={equipmentId}
                                onChange={(e) => {
                                    setEquipmentId(
                                        processValue(e.target.value)
                                    );
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>
                        <div className="input-wrapper">
                            <div className="merge-field-label">Dealer Name</div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={dealerName}
                                onChange={(e) => {
                                    setDealerName(processValue(e.target.value));
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Serial Number
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={serialNumber}
                                onChange={(e) => {
                                    setSerialNumber(
                                        processValue(e.target.value)
                                    );
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">
                                Technician Name
                            </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={techName}
                                onChange={(e) => {
                                    setTechName(processValue(e.target.value));
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>

                        <div className="input-wrapper">
                            <div className="merge-field-label">Branch </div>
                            <i className="fas fa-angle-left" />
                            <input
                                value={branch}
                                onChange={(e) => {
                                    setBranch(processValue(e.target.value));
                                }}
                                className="merge-field-input"
                            />
                            <i className="fas fa-angle-right" />
                        </div>
                    </div>
                    <div style={{ textAlign: "right", marginTop: 15 }}>
                        <AppButton
                            onClick={handleClick}
                            className="btn btn-primary"
                            isLoading={isLoading}
                        >
                            Update
                        </AppButton>
                    </div>
                </div>
            </div>
        </div>
    );

    function processValue(val: string) {
        val = val.replace("<", "");
        val = val.replace(">", "");
        return val;
    }

    async function handleClick() {
        setIsLoading(true);
        try {
            let result = await apiService.updateLabelMapping({
                id,
                branch,
                customerName,
                workOrderNumber,
                customerNumber,
                make,
                model,
                date,
                serviceCallBackNumber,
                equipmentId,
                dealerName,
                serialNumber,
                techName,
            });
            console.log(result);
            showNotification("updated", "success");
        } catch (error) {
            handleErrors(error);
        }
        setIsLoading(false);
    }
}
