import React, { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
// import { RoleContext } from "../storage/AppStorage";

export default function () {
    const location = useLocation();
    const [role, setRole] = useState("" as any);

    useEffect(() => {
        let canEdit = localStorage.getItem("canEdit");
        setRole(canEdit);
    }, []);

    return (
        <div
            className="sidebar small"
            data-color="white"
            data-active-color="danger"
        >
            <div className="logo">
                <img className="img" src={"/logo.png"} />
                <div className="subtitle">
                    Communication <br /> Dashboard
                </div>

                <button
                    className="close-menu"
                    onClick={() => {
                        const $ = (window as any).jQuery;
                        $(".sidebar").removeClass("open");
                    }}
                >
                    <i className="fas fa-times"></i>
                </button>
            </div>
            <div className="sidebar-wrapper">
                <ul className="nav">
                    {getList(
                        "Service Dashboard",
                        "/",
                        <i className="fas fa-chart-bar" />
                    )}
                    {getList(
                        "History",
                        "/history",
                        <i className="fas fa-history"></i>
                    )}
                    {role == "True" ? (
                        <Fragment>
                            {getList(
                                "Messages",
                                "/messages",
                                <i className="fas fa-envelope-open-text" />
                            )}
                            {getList(
                                "Customization",
                                "/merge-fields",
                                <i className="fas fa-tags" />
                            )}
                        </Fragment>
                    ) : (
                        <Fragment />
                    )}

                    {/* {getList(
                        "User Profile",
                        "/user-profile",
                        <i className="far fa-id-badge" />
                    )} */}
                </ul>
            </div>
        </div>
    );

    function getList(name, url, icon) {
        return (
            <li className={checkActive(url)}>
                <Link to={url}>
                    {icon}
                    <p>{name}</p>
                </Link>
            </li>
        );
    }

    function checkActive(name) {
        return name == location.pathname ? "active" : "";
    }
}
